import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { store, saveState } from './store'
import { Provider } from 'react-redux'

import './setupFirebase'

store.subscribe(() => {
  saveState(store.getState())
})

ReactDOM.render(
 
    <Provider store={store}>
      <React.StrictMode>
        <Router>
          <App />
        </Router>
      </React.StrictMode>
    </Provider>,
  document.getElementById('root')
)