import React from 'react'
import Product from './Product';

const ProductList = ({ services = [] }) => {
  
  return (
    <>
    {/* <p>Brands listing</p> */}
     { services.length > 0 ? (
      services.map((service) => {
        return service.brands.map((brand, index) => (
          <div key={index}>{brand}</div>
        ))
    }) ): (
        <div className="flex justify-center">
          <p className="text-center text-gray-500">No products found</p>
        </div>
      )
    }
    </>
  );
}



export default ProductList