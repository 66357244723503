import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const usersApi = createApi({
    reducersPath: "users",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.tausiapp.com/api/v2",
        prepareHeaders: (headers) => {
            const token = sessionStorage.getItem('Auth Token');
            if(token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    tagTypes: ['Users'],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => `users/?limit=10000000`,
            providesTags: ['Users'],
        })
    })
})

export const { useGetUsersQuery } = usersApi;
