import React from 'react'
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai';

const ReviewsCard = ({rate}) => {
  return (
    <div className='p-3 mt-2'>
        <div className='flex justify-between'>
            <div className='flex space-x-1 items-center'>
            <img className='w-10 h-10 rounded-full object-center object-cover' src={rate.professional.user.profilePictureUrl} alt="profile_picture"/>
            <div>
                <p className='text-xs font-light text-gray-600 capitalize'>{rate.professional.user.name}</p>
                <p className='text-xs font-light text-gray-600'>{rate.professional.businessName}</p>
            </div>
            </div>
            <div className=''>
            {
            (() => {
                if (rate.rating === 1) {
                    return (
                        <div className='flex space-x-1 items-center'> 
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rate.rating}.0</p>
                        </div>
                    )
                } else if (rate.rating === 2) {
                    return (
                        <div className='flex space-x-1 items-center'> 
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rate.rating}.0</p>
                        </div>
                    )
                } else if (rate.rating === 3) {
                    return (
                        <div className='flex space-x-1 items-center'> 
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rate.rating}.0</p>
                        </div>
                    )
                } else if (rate.rating === 4) {
                    return (
                        <div className='flex space-x-1 items-center'> 
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiOutlineStar />
                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rate.rating}.0</p>
                        </div>
                    )
                } else if (rate.rating === 5) {
                    return (
                        <div className='flex space-x-1 items-center'> 
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rate.rating}.0</p>
                        </div>
                    )
                } else {
                    return (
                        <div className='flex space-x-1 items-center'> 
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <AiOutlineStar />
                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rate.rating}.0</p>
                        </div>
                    )
                }
            })()
            }
            </div>
        </div>
        <p className='text-xs mt-2 font-normal text-gray-600'>Comment: {rate.comment}</p>
    </div>
  )
}

export default ReviewsCard