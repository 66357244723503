import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify'
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import GlobalFilter from '../partials/actions/GlobalFilter';
import AddCategory from '../partials/modals/AddCategory';

function Table4({ columns, data, resource, func, isLoading, total }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageOptions,
      gotoPage,
      pageCount,
      setPageSize,
      prepareRow,
      state,
      setGlobalFilter,
      allColumns,
    } = useTable({columns, data}, useGlobalFilter, useSortBy, usePagination )

    const { globalFilter, pageIndex, pageSize } = state;
    const [visible, setVisible] = useState(false);
    // const [modalData, setModalData] = useState({}) 
    const [isOpen, setIsOpen] = useState(false)

    const handleButtonClick = (e) => {
        setVisible(true)
    };
    const handleCloseButton = () => {
        setVisible(false)
    }


    const handleCheckboxs = () => {
        setIsOpen(true)
    }
    const handleClose = () => {
        setIsOpen(false)
    }

    const openColumnToggle = () => 
    <svg className="w-6 h-6 cursor-pointer hover:text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={() => handleClose()}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
    </svg>

    const closeColumnToggle = () => 
    <svg className="w-6 h-6 cursor-pointer hover:text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={() => handleCheckboxs()}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path>
    </svg>
   

    // Render the UI for your table 
    return (
    <div className="relative overflow-x-auto sm:rounded-lg">
        <ToastContainer />
        <div className="flex justify-between items-center mb-5 pt-4 lg:pt-5">
            <div className='flex items-center space-x-4'>
                <button className='px-7 py-2 rounded-full bg-blue-500 text-white font-medium text-xs hover:bg-blue-600' onClick={e => handleButtonClick(e)}>+ Add {resource}</button>

                {!isOpen ? 
                    closeColumnToggle()
                : 
                    openColumnToggle() 
                }
                <p className="text-sm italic font-normal text-gray-600">Total: {total} Categories</p>
            </div>

            {/* Add Service Modal */}
            <AddCategory visible={visible} handleCloseButton={handleCloseButton} func={func} handleVisible={() => setVisible()}/>
            {/* End Service Modal */}

            <div className='flex items-center space-x-1'>
                <button type="button" className={`${isLoading ? 'flex' : 'hidden'} px-3 py-1 items-center text-blue-500`} disabled>
                    <svg className="animate-spin h-9 w-9 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span class="text-base font-medium">Loading...</span>
                </button>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
        </div>
        
        {/* Column Collapse */}
        <div className={`${isOpen? 'flex' : 'hidden'} space-x-5 items-center mb-3 ml-1 transition duration-500 ease-in-out`}>
            {allColumns.map(column => (
                <div key={column.id} className='capitalize'>
                    <label className='text-sm font-medium text-gray-900'>
                        <input type="checkbox" {...column.getToggleHiddenProps()} className="w-3.5 h-3.5 accent-green-500 border border-gray-300 rounded bg-gray-50 focus:ring-1 focus:ring-blue-300"/>{' '}
                        {column.Header}         
                    </label>
                </div>
            ))}
        </div>

        <table {...getTableProps()} className="w-full text-sm text-left text-gray-600 shadow-md border border-gray-200 rounded-xl">
            {/* Head */}
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-lg">
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200">
                    <th scope="col" className="p-4">
                        <div className="flex items-center">
                            <input id="checkbox-all" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                            <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
                        </div>
                    </th>
                    {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="py-3 min-w-36">
                        <div className='flex space-x-3 items-center'>
                            {column.render('Header')}
                            <span>
                                {column.isSorted ?  (column.isSortedDesc ? 
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg> : 
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"></path></svg>
                                ) : ''}
                            </span>
                        </div>
                    </th>
                    ))}
                </tr>
            ))}
            </thead>

            {/* Body */}
            <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row)
                return (
                <tr {...row.getRowProps()} className="bg-white border-b hover:bg-gray-50"> 
                    <td className="w-4 p-3">
                        <div className="flex items-center">
                            <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-1"/>
                            <label htmlFor="checkbox-table-1" className="sr-only">checkbox</label>
                        </div>
                    </td>
                    {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                </tr>
                )
            })}
            </tbody>
        </table>

        {/* Pagination buttons */}
        <nav
            className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-0 sm:grid-cols-12"
            aria-label="Pagination"
            >
            <div className="hidden sm:flex space-x-5 items-center">
                <p className="text-sm text-gray-700">
                Showing page <span className="font-medium text-gray-900">{pageIndex + 1} </span> of <span className="font-medium text-gray-900">{pageOptions.length}</span>
                </p>
                <select className='py-0.5 text-xs outline:focus-none rounded-lg focus:ring-blue-300 focus:ring-1' value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                {
                    [10, 20, 30, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))
                }
                </select>
            </div>

            <div className="flex-1 flex justify-between sm:justify-end">
                <button
                className="relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path>
                    </svg>
                </button>

                <button
                className="ml-3 relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                >Previous</button>

                <button
                className="ml-3 relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                >Next</button>

                <button
                className="ml-3 relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
                    </svg>
                </button>
            </div>
        </nav>
        
    </div>
    )
  }

export default Table4;