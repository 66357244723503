import React, { useState, useEffect, Suspense } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import ApplicantTab from '../partials/applications/ApplicantTab';
import { fetchVerificationById } from '../api/professional/verification';


function ApplicationDetails({ loggedUser, logout }) {


  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [applicant, setApplicant] = useState({});
  const [services, setServices] = useState([])
  const [brands, setBrands] = useState([])
  const [resume, setResume] = useState('')
  const [loading, setLoading] = useState(false);

  const ApplicationProfile = React.lazy(() => import('../partials/applications/ApplicantsProfile'));
  // const ApplicationTab = React.lazy(() => import('../partials/applications/ApplicationTab'));


  const fetchData = async () => {
    setLoading(true);
    const search = new URLSearchParams(window.location.search); 
    const id = search.get('id');
    
    try {
      const fetchApplicationQuery = await fetchVerificationById(id);
      console.log(fetchApplicationQuery)

      if(fetchApplicationQuery.status === 200) {
        setApplicant(fetchApplicationQuery.data);
        setServices(fetchApplicationQuery.data.professional.services)
        setResume(fetchApplicationQuery.data.professional.resumePath)
        // console.log(fetchApplicationQuery.data.professional.resume)
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

  };
  
  useEffect(() => {
      fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} title='Application Details' loggedUser={loggedUser} logout={logout}/>

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-gray-100">

            {/* Cards */}
            <div className="grid grid-cols-12 gap-4 sm:gap-6 mb-10">

              {/* Grid 1 */}
              <div className="col-span-full sm:col-span-5 space-y-6">
                { !loading  && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ApplicationProfile applicant={applicant} />
                  </Suspense>
                ) }
              </div>

              {/* Grid 2 */}
              <div className="col-span-full sm:col-span-7 space-y-6">
                <ApplicantTab applicant={applicant} services={services} brands={brands} resume={resume}/>
              </div>          
            </div>
          </div>
        </main>

      </div>
    </div>
  );
}

export default ApplicationDetails;