import React, { useState } from "react";

import FetchOrders from "../../tables/FetchOrders";
import FetchOngoingOrders from '../orders/tables/FetchOngoingOrders';
import FetchCompletedOrders from '../orders/tables/FetchCompletedOrders';
import FetchPendingOrders from "../orders/tables/FetchPendingOrders";
import FetchCancelledOrders from '../orders/tables/FetchCancelledOrders';
import FetchScheduledOrders from "../orders/tables/FetchScheduledOrders";

function OrdersTab () {

  const [openTab, setOpenTab] = useState(1);

  return (
    <div className="grid col-span-full">
      <div className="flex flex-wrap">
        <div className="w-full">

            <div className="grid gap-4 sm:flex items-center justify-between">
                <ul
                    className="flex mb-0 list-none flex-wrap rounded-full flex-row bg-white order-2 sm:order-1"
                    role="tablist"
                >
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openTab === 1
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenTab(1);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                    >
                        All Orders
                    </a>
                    </li>

                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openTab === 2
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenTab(2);
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist"
                    >
                        Completed
                    </a>
                    </li>
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openTab === 3
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenTab(3);
                        }}
                        data-toggle="tab"
                        href="#link3"
                        role="tablist"
                    >
                        Ongoing
                    </a>
                    </li>
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openTab === 4
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenTab(4);
                        }}
                        data-toggle="tab"
                        href="#link4"
                        role="tablist"
                    >
                        Pending
                    </a>
                    </li>
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openTab === 5
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenTab(5);
                        }}
                        data-toggle="tab"
                        href="#link5"
                        role="tablist"
                    >
                        Scheduled
                    </a>
                    </li>

                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openTab === 6
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenTab(6);
                        }}
                        data-toggle="tab"
                        href="#link6"
                        role="tablist"
                    >
                        Cancelled
                    </a>
                    </li>
                </ul>
            </div>

            {/* Content */}
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 rounded">
                <div className="flex-auto">
                    <div className="tab-content tab-space">
                        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                            <FetchOrders />
                        </div>
                        <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                            <FetchCompletedOrders />
                        </div>
                        <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                            <FetchOngoingOrders />
                        </div>
                        <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                            <FetchPendingOrders />
                        </div>
                        <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                            <FetchScheduledOrders />
                        </div>
                        <div className={openTab === 6 ? "block" : "hidden"} id="link6">
                            <FetchCancelledOrders />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersTab;