import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// import SidebarLinkGroup from './SidebarLinkGroup';

import Logo from '../images/TausiLogo-dark.png';

function Sidebar({ sidebarOpen, setSidebarOpen }) {

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div className={`fixed inset-0 bg-gray-100 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-white shadow pt-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
      >

        {/* Sidebar header */}
        <div className="flex space-x-3 sm:space-x-0 sm:justify-between mb-10 pr-3 lg:pl-5">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>

          {/* Logo */}
          <NavLink end to="/" className="block">
            <p className="sr-only">Tausi Dashboard Logo</p>
            <img className="w-full h-16 object-cover object-center" src={Logo} alt="logo"/>
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-12 pl-5">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span>
            </h3>
            <ul className="mt-3">

              {/* Dashboard */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/' && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`text-slate-600 ${pathname === '/' && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span className={`text-sm font-semibold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/' && '!text-red-600'}`}>Dashboard</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>

              {/* Orders */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/orders' && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/orders" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/orders' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/orders' && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/orders' && '!text-red-600'}`}>Orders</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/orders' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>
           
              {/* Revenues */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/revenues' && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/revenues" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/revenues' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/revenues' && '!text-red-600'}`}  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/revenues' && '!text-red-600'}`}>Revenues</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/revenues' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>

              {/* Users */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/users'  && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/users" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/users'  && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/users'  && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/users'  && '!text-red-600'}`}>Users</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/users'  && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>

              {/* Categories */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/categories' && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/categories" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/categories' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/categories' && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/categories' && '!text-red-600'}`}>Categories</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/categories' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>

               {/* Services */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/services' && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/services" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/services' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/services' && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/services' && '!text-red-600'}`}>Services</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/services' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>

              {/* Applications */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/applications' && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/applications" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/applications' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/applications' && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/applications' && '!text-red-600'}`}>Applications</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/applications' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>

              {/* Messaging */}
              {/* <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/messaging' && 'bg-pink-100 bg-opacity-70'}`}>
                <NavLink end to="/messaging" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/messaging' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                   <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/messaging' && 'text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/messaging' && '!text-red-600'}`}>Messaging</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/messaging' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li> */}

              {/* Setting */}
              <li className={`rounded-sm mb-0.5 flex justify-between last:mb-0 ${pathname === '/settings' && 'bg-pink-100 bg-oapcity-70'}`}>
                <NavLink end to="/settings" className={`px-3 py-4 block text-slate-600 hover:text-slate-800 truncate transition duration-150 ${pathname === '/settings' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                    <svg className="shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path className={`${pathname === '/settings' && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
                      </path>
                      <path className={`${pathname === '/settings' && '!text-red-600'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                    <span className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname === '/settings' && '!text-red-600'}`}>Settings</span>
                  </div>
                </NavLink>
                <span className={`${pathname === '/settings' && 'border-r-3 border-red-600 rounded-t-md rounded-b-md'}`}></span>
              </li>
           
           
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Sidebar;