import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'

import axiosInstance from '../../../axiosConfig';
import Table5 from '../../../tables/Table5';

function FetchCompletedOrders() {
    const columns = useMemo(() => [
        {
            Header: "Order By",
            accessor: 'client.name',
            id: 'Order By'
        },
        {
            Header: "Service Ordered",
            accessor: 'service.serviceData.name',
            id: 'Service Ordered',
        },
        {
            Header: "Provider",
            accessor: 'professional.businessName',
            id: 'Price',
        },
        {
            Header: "Order Date/Time",
            accessor: 'time.date',
            id: 'Order Date',
            Cell: ({ row }) => {
                let ordereddate = row.original.createdAt
                let converted = new Date(ordereddate)
                let date = converted.toDateString()
                let time = converted.getHours() + ':' + converted.getMinutes().toString().padStart(2, "0");
                return (
                    <span className='text-sm mr-5'>{date}, {time}</span>
                )
            }
        },
        {
            Header: "Status",
            accessor: d => d.status.toString(),
            Cell: ({ row }) => {
                const x = row.original
                return (
                    x.status === "completed" ? 
                    <button className='text-xs py-1 px-4 bg-green-100 rounded-full text-green-500 cursor-default font-normal'>Completed</button>                 
                    : 
                    ""
                )
            },
        },
        {
            Header: "Action",
            id: "Action",
            Cell: ({ row }) => (
              <button className='text-white hover:bg-blue-600 text-xs font-medium py-1 px-4 rounded-full bg-blue-400' onClick={ () => handleButtonClick(row)}>
                View
              </button>
            )
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);
    const [data, setData] = useState(useMemo(() => [], []));
    const [length, setLength] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const fetchData = () => {
        setIsLoading(true)
        axiosInstance.get('/appointments?limit=100000000')
        .then(response => {
            const orders = response.data.data;
            const completedOrders = orders.filter(order => order.status === 'completed');
            setData(completedOrders);
            setLength(completedOrders.length);
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error)
            setIsLoading(false);
        });
    };
    
    useEffect(() => {
        fetchData()
    }, []);

    const navigate = useNavigate();
    
    const handleButtonClick = (row) => {
        navigate(`${row.original.id}`);
    };

    return (
        <>
            <Table5 columns={columns} data={data} csvData={data} fileName='orders' isLoading={isLoading} total={length}/>
        </>
    )
}

export default FetchCompletedOrders;