import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axiosConfig";

export const getOrders = createAsyncThunk(
    "appointments?limit=1000000000",
    async () => {
        try {
        const response = await axiosInstance.get(
             "https://api.tausiapp.com/api/v2"
        );
        return response.data;
        } catch (error) {
        console.error(error);
    }
});

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    ordersList: [],
    isLoading: false,
    hasError: false
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
    })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.ordersList = action.payload;
        state.isLoading = false;
        state.hasError = false
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.hasError = true
        state.isLoading = false;
      })
  }
});

// Selectors
export const selectOrders = state => state.orders.ordersList;
export const selectLoadingState = state => state.orders.isLoading;
export const selectErrorState = state => state.orders.hasError;

export default ordersSlice.reducer;
