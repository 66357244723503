import React from 'react';
import Default from '../../images/default.png';

function ClientCard({ data }) {
    const clientName = data.client;
    const name = clientName?.name;
    const profilePicUrl = data.client;
    const avatar = profilePicUrl?.profilePictureUrl;
    const location = data.client?.locationAddress;
    const totalSpend = data.metaData?.totalSpendForClient;
    const totalOrders = data.metaData?.totalOrdersForClient;
  

  return (
        <div className="col-span-full bg-white shadow-lg rounded-xl border border-slate-200 p-6">
            <h2 className='font-bold text-lg text-gray-900'>Client</h2>

            <div className='flex  space-x-6 pt-4 pb-5'>
                <div className=''>
                    {!avatar ? 
                    (
                        <img 
                    className="w-20 h-20 object-center object-cover rounded-full" 
                    src={Default} 
                    alt="img"/>
                    ) 
                    : 
                    (
                    <img 
                    className="w-20 h-20 object-center object-cover rounded-full" 
                    src={avatar} 
                    alt="img"/>
                    )
                    }
                </div>
              
                <div>
                    <div className="grid grid-cols-1 gap-2 justify-between items-center border-b border-gray-300 pb-5">
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Client Name:</p>
                            <p className='text-sm text-blue-800 font-semibold mt-1 capitalize'>{name}</p>
                        </div>
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Location:</p>
                            <p className='text-xs text-blue-800 font-normal mt-1'>{location}</p>
                        </div>
                    </div>

                    <div className='grid grid-cols-2 justify-start gap-8 pt-4'>
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Orders Made:</p>
                            <p className='text-sm text-blue-800 font-semibold mt-1'>{ totalOrders }</p>
                        </div>
                        
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Total Expense:</p>
                            <p className='text-sm text-blue-800 font-semibold mt-1'>Ksh { totalSpend }</p>
                        </div>
                        {/* <div className='grid justify-center py-1 h-8 w-8 rounded-full bg-red-600'>
                            <svg className="w-6 h-6 text-white fill-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path></svg>
                        </div> */}
                    </div>
                </div>
            </div>

        </div>
  );
}

export default React.memo(ClientCard);
