import React, { useEffect, useMemo, useState } from "react";
import { fetchAllProfessionalVerifications, fetchProfessionalByStatus } from "../../api/professional/verification";
import FetchAllApplicants from '../../tables/FetchAllApplicants'

function ApplicationTab() {

    const [openApplicationTab, setOpenApplicationTab] = useState(1);
    const [data, setData] = useState(useMemo(() => [], []));
    const [loading, setLoading] = useState(false);

    const FetchApplications = React.lazy(() => import("../../tables/FetchApplications"));

    const fetchData = async () => {
        const fetchVerificationsQuery = await fetchAllProfessionalVerifications();

        if (fetchVerificationsQuery.status === 200) {
            setData(fetchVerificationsQuery.data);
        }
    };

    useEffect(() => {
        setData([]);
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSortedVerifications = async (status = '', tabNumber = 1) => {
        setLoading(true);
        setOpenApplicationTab(tabNumber);
        setData([]);

        if (tabNumber === 2 && status === '') {
            fetchData();
            return;
        } else {
            const filteredData = await fetchProfessionalByStatus(status);
            console.log(filteredData)

            if (filteredData.status === 200) {
                setData(filteredData.data);
            }
        }

        setLoading(false);
    }
    console.log(loading)

    return (
        <div className="grid col-span-full">
            <div className="flex flex-wrap">
                <div className="w-full">

                    <div className="grid gap-4 sm:flex items-center justify-between">
                        <ul
                            className="flex mb-0 list-none flex-wrap rounded-full flex-row bg-white order-2 sm:order-1"
                            role="tablist"
                        >
                            <li className="-mb-px last:mr-0 flex-auto text-center">
                                <a
                                    className={
                                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                                        (openApplicationTab === 1
                                            ? "text-white bg-red-500"
                                            : "text-gray-600 hover:text-blue-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        fetchSortedVerifications('', 1)
                                    }}
                                    data-toggle="tab"
                                    href="#all"
                                    role="tablist"
                                >
                                    All Applicants
                                </a>
                            </li>
                            <li className="-mb-px last:mr-0 flex-auto text-center">
                                <a
                                    className={
                                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                                        (openApplicationTab === 2
                                            ? "text-white bg-red-500"
                                            : "text-gray-600 hover:text-blue-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        fetchSortedVerifications('', 2)
                                    }}
                                    data-toggle="tab"
                                    href="#latest"
                                    role="tablist"
                                >
                                    Latest
                                </a>
                            </li>

                            <li className="-mb-px last:mr-0 flex-auto text-center">
                                <a
                                    className={
                                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                                        (openApplicationTab === 3
                                            ? "text-white bg-red-500"
                                            : "text-gray-600 hover:text-blue-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        fetchSortedVerifications('pending', 3)
                                    }}
                                    data-toggle="tab"
                                    href="#pending"
                                    role="tablist"
                                >
                                    Pending
                                </a>
                            </li>
                            <li className="-mb-px last:mr-0 flex-auto text-center">
                                <a
                                    className={
                                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                                        (openApplicationTab === 4
                                            ? "text-white bg-red-500"
                                            : "text-gray-600 hover:text-blue-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        fetchSortedVerifications('review', 4)
                                    }}
                                    data-toggle="tab"
                                    href="#review"
                                    role="tablist"
                                >
                                    In Review
                                </a>
                            </li>

                            <li className="-mb-px last:mr-0 flex-auto text-center">
                                <a
                                    className={
                                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                                        (openApplicationTab === 5
                                            ? "text-white bg-red-500"
                                            : "text-gray-600 hover:text-blue-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        fetchSortedVerifications('rejected', 5)
                                    }}
                                    data-toggle="tab"
                                    href="#rejected"
                                    role="tablist"
                                >
                                    Rejected
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Content */}
                    <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 rounded">
                        <div className="flex-auto">
                            <React.Suspense fallback={<div className="text-sm font-medium">Loading...</div>}>
                            <div className="tab-content tab-space">
                                <div className={openApplicationTab === 1 ? "block" : "hidden"} id="all">
                                    <FetchAllApplicants />
                                </div>
                                <div className={openApplicationTab === 2 ? "block" : "hidden"} id="latest">
                                    <FetchApplications applicationData={data} />
                                </div>
                                <div className={openApplicationTab === 3 ? "block" : "hidden"} id="pending">
                                    <FetchApplications applicationData={data} />
                                </div>
                                <div className={openApplicationTab === 4 ? "block" : "hidden"} id="review">
                                    <FetchApplications applicationData={data} />
                                </div>
                                <div className={openApplicationTab === 5 ? "block" : "hidden"} id="rejected">
                                    <FetchApplications applicationData={data} />
                                </div>
                            </div>
                            </React.Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationTab;