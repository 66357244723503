import React from 'react'
import axiosInstance from '../../axiosConfig'
import { toast } from 'react-toastify'
import {  useSelector } from 'react-redux'
import { useFormik } from 'formik'

const ServiceModal = ({isOpen, handleCloseButton, modalData, selectedCategory, func}) => {

  const serviceCats = useSelector((state) => state.categories.value);
  const filteredServiceCats = serviceCats.filter(fCats => fCats.id !== selectedCategory.id)

  const id = modalData.id;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: modalData.name,
      serviceImage: {
        mediaUrl: '',
      },
      description: modalData.description,
      minimumPrice: modalData.minimumPrice,
      // serviceDuration: modalData.serviceDuration,
      category: modalData.category,

    },

    onSubmit: (values, { resetForm }) => {
      console.log(JSON.stringify(values, null, 2));
      const uri = `/services/${id}`;
      const params = JSON.stringify(values);
      const headers = {
        'Content-Type': 'application/json'
      };
      axiosInstance.patch(uri, params, { headers })
      .then(() => {
        toast.success('Service updated successfully!', { 
          autoClose: 4000, 
        })
        resetForm()
        func()
        handleCloseButton()
      })
      .catch((err) => {
        console.log(err)
      })
    }
  })

  return (

    <div className={`overflow-y-auto ${isOpen ? 'fixed z-50 inset-0' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
         
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div className="mx-auto flex items-center justify-center">
                  <h2 className='text-xl font-medium py-3'>Edit Service</h2>
              </div>
              <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                <div className="mt-3 sm:mt-5">
                    <div className='grid'>
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                        <input 
                        type="text" 
                        id="name" 
                        name="name" 
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                        placeholder="Service name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        />
                        {/* {errors.name && touched.name && errors.name} */}  
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="mt-3 sm:mt-5">
                      <div className='grid'>
                          <label htmlFor="minimumPrice" className="block mb-2 text-sm font-medium text-gray-900">Minimum Price</label>
                          <input 
                          type="number" 
                          id="minimumPrice" 
                          name="minimumPrice" 
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                          placeholder="Minimum price"
                          onChange={formik.handleChange}
                          value={formik.values.minimumPrice}
                          />
                          {/* {errors.name && touched.name && errors.name} */}  
                      </div>
                  </div>
                  {/* <div className="mt-3 sm:mt-5">
                      <div className='grid'>
                          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Service Duration</label>
                          <input 
                          type="number" 
                          id="serviceDuration" 
                          name="serviceDuration" 
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                          placeholder="Service duration"
                          onChange={formik.handleChange}
                          value={formik.values.serviceDuration}
                          />
                      </div>
                  </div> */}
                </div>

                <div className='mt-4'>
                    <label htmlFor="categories" className="block mb-2 text-sm font-medium text-gray-900">Category</label>
                    <select 
                    id="category" 
                    name="category" 
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm sm:text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                    onChange={formik.handleChange}
                    >
                      <option value={selectedCategory.id}>{selectedCategory.name}</option>
                      {filteredServiceCats && filteredServiceCats.map(cat => {
                          return <option value={cat.id} key={cat.id} >{cat.name}</option>
                      })}
                    </select>
                    {/* <span class="text-xs text-red-500">{errors.serviceCategoryId && touched.serviceCategoryId && errors.serviceCategoryId}</span> */}
                </div>

                <div className='mt-4'>
                    <label htmlFor="serviceImage" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                    <input 
                    type="file" 
                    id="serviceImage" 
                    name="serviceImage.mediaUrl" 
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                    onChange={formik.handleChange}
                    value={formik.values.serviceImage.mediaUrl}
                    />
                </div>

                <div className="mt-4">
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
                    <textarea 
                    id="description" 
                    name="description" 
                    rows="4" 
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                    placeholder="Description" 
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    ></textarea>
                    {/* <span class="text-xs text-red-500">{errors.description && touched.description && errors.description}</span> */}
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm" >Update</button>
                  <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={handleCloseButton}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
      </div>
    </div>
    
  )
}

export default ServiceModal
