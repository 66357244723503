import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../axiosConfig';
// import { toast } from 'react-toastify'
import Table from './Table';
import UserModal from '../partials/modals/UserModal';

// import { useGetUsersQuery } from "../../src/services/users";


function FetchUsers() {

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Email",
            accessor: 'email',
        },
        {
            Header: "Phone",
            accessor: 'phoneNumber',
        },
        {
            Header: "Active Since",
            accessor: 'createdAt',
            id: 'Active Since',
            Cell: ({ row }) => {
                let date = row.original.createdAt
                return new Date(date).toDateString()
            }
        },
        {
            Header: "User Type",
            accessor: 'createdAt',
            id: 'User Type',
            Cell: ({ row }) => {
                let type = row.original.sessionData?.userTypeSession
                return <p className='capitalize'>{type}</p>;
            }
        },
        {
            Header: "Status",
            accessor: d => d.isActive.toString(),
            Cell: ({ row }) => {
                const x = row.original
                return (
                    x.isActive === true ? 
                    <button className='text-xs py-1 px-4 bg-green-100 rounded-full text-green-500 cursor-default font-normal'>Active</button>                 
                    : 
                    <button className='text-xs py-1 px-4 bg-red-100 rounded-full text-red-500 cursor-default font-normal'>Inactive</button>
                )
            },
        }, 
        {
            Header: "Action",
            id: "action",
            Cell: ({ row }) => (
             <div>
                <button onClick={e => handleButtonClick(e, row)} className='text-white hover:bg-blue-600 text-xs font-medium py-1 px-4 rounded-full bg-blue-400'>
                    View
                </button>
                {/* <button onClick={() => handleDelete(row)} className='text-white hover:bg-red-600 text-xs font-medium ml-2 py-1 px-4 rounded-full bg-red-400'>
                    Delete
                </button> */}
             </div>
            )
        },
       
        
    ], []);

    // const { data: users, refetch } = useGetUsersQuery();

    const [usersData, setUsersData] = useState(useMemo(() => [], []));
    const [visible, setVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [length, setLength] = useState(0);
    const [ratings, setRatings] = useState([])

    // const fetchUsersData = () => {
    //     setUsersData(users.data);
    //     setLength(users.data.length);
    // }

    // const mountedRef = useRef(true);

    // useEffect(() => {
    //     if(mountedRef) {
    //         fetchUsersData();
    //         refetch();
    //     }
    //     return () => { mountedRef.current = false }
    // }, []);

    const fetchData = async () => {
        await axiosInstance.get('/users/?limit=10000000')
        .then(response => {
            const users = response.data.data;
            setUsersData(users);
            setLength(users.length)
        })
        .catch(error => {
            console.log(error)
        })
    };

    useEffect(() => {
        const ac = new AbortController();
        fetchData();
        return () => ac.abort();
    }, []);


    const handleButtonClick = (e, row) => {
        setVisible(true)
        let values = row.original;
        setModalData(values);

        const { id } = values;

        const fetchClientRating = async () => {
            await axiosInstance.get(`/ratings/client/${id}`)
            .then(response => {
                const { data } = response.data
                setRatings(data);
            })
            .catch(error => {
                console.log(error);
            })
        };

        const fetchProfessionalRating = async () => {
            await axiosInstance.get(`/ratings/professional/${id}`)
            .then(response => {
                console.log('REsponse', response)
                const { data } = response.data
                setRatings(data);
            })
            .catch(error => {
                console.log(error);
            })
        }

        fetchClientRating();
        fetchProfessionalRating(); 

    };
    const handleCloseButton = () => { 
        setVisible(false)
    }

    // const handleDelete = (row) => {
      
    //     const userId = row.original.id

    //     axiosInstance.delete(`/users/${userId}`)
    //     .then(res => {
    //         console.log(res)
    //         toast.success('User has been deleted')
    //     })
    //     .catch(err => {
    //         console.log(err)
    //     })
    // }

    return (
        <>
            <Table columns={columns} data={usersData} csvData={usersData} fileName='users' resource='User' total={length}/>
            <UserModal 
            isOpen={visible} 
            handleCloseButton={handleCloseButton} 
            name={modalData.name} 
            id={modalData.id}
            email={modalData.email}
            phoneNumber={modalData.phoneNumber}
            active={modalData.isActive}
            bio={modalData.bio}
            avatar={modalData.profilePictureUrl}
            userType={modalData.sessionData?.userTypeSession}
            businessName={modalData.professional?.businessName}
            locationPro={modalData.professional?.locationAddress}
            ratings={ratings}
            />
        </>
    )
}

export default React.memo(FetchUsers);