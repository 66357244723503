import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: null,
}

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        getCategories: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { getCategories } = categoriesSlice.actions
export default categoriesSlice.reducer