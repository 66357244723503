import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0,
}

export const secondsSlice = createSlice({
    name: 'seconds',
    initialState,
    reducers: {
        getSeconds: (state, action) => {
            state.value = action.payload
        },
    }
})

export const { getSeconds } = secondsSlice.actions
export default secondsSlice.reducer