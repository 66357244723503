import React, { useState } from "react";
import FetchUsers from "../../tables/FetchUsers";
import FetchServiceProviders from "../users/tables/FetchServiceProviders";
import FetchClients from "../users/tables/FetchClients";
import FetchActiveUsers from "../users/tables/FetchActiveUsers";
import FetchInactiveUsers from "../users/tables/FetchInactiveUsers";

function UsersTab () {

  const [openUserTab, setOpenUserTab] = useState(1);

  return (
    <div className="col-span-full">
      <div className="flex flex-wrap">
        <div className="w-full">

            <div className="grid gap-4 sm:flex items-center justify-between">
                <ul
                    className="flex mb-0 list-none flex-wrap rounded-full flex-row bg-white order-2 sm:order-1"
                    role="tablist"
                >
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openUserTab === 1
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenUserTab(1);
                        }}
                        data-toggle="tab"
                        href="#allusers"
                        role="tablist"
                    >
                        All Users
                    </a>
                    </li>

                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openUserTab === 2
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenUserTab(2);
                        }}
                        data-toggle="tab"
                        href="#serviceproviders"
                        role="tablist"
                    >
                        Service Providers
                    </a>
                    </li>
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openUserTab === 3
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenUserTab(3);
                        }}
                        data-toggle="tab"
                        href="#clients"
                        role="tablist"
                    >
                        Clients
                    </a>
                    </li>

                    {/* <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openUserTab === 4
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenUserTab(4);
                        }}
                        data-toggle="tab"
                        href="#internal"
                        role="tablist"
                    >
                        Internal Users
                    </a>
                    </li> */}
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openUserTab === 5
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenUserTab(5);
                        }}
                        data-toggle="tab"
                        href="#active"
                        role="tablist"
                    >
                        Active
                    </a>
                    </li>
                    <li className="-mb-px last:mr-0 flex-auto text-center">
                    <a
                        className={
                        "text-xs font-semibold px-5 py-2 rounded-full block leading-normal " +
                        (openUserTab === 6
                            ? "text-white bg-red-500"
                            : "text-gray-600 hover:text-blue-400 bg-white")
                        }
                        onClick={e => {
                        e.preventDefault();
                        setOpenUserTab(6);
                        }}
                        data-toggle="tab"
                        href="#inactive"
                        role="tablist"
                    >
                        Inactive
                    </a>
                    </li>
                </ul>
            </div>

            {/* Content */}
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 rounded">
                <div className="flex-auto">
                    <div className="tab-content tab-space">
                        <div className={openUserTab === 1 ? "block" : "hidden"} id="allusers">
                        <FetchUsers />
                        </div>
                        <div className={openUserTab === 2 ? "block" : "hidden"} id="serviceproviders">
                        <FetchServiceProviders />
                        </div>
                        <div className={openUserTab === 3 ? "block" : "hidden"} id="clients">
                        <FetchClients />
                        </div>
                        {/* <div className={openUserTab === 4 ? "block" : "hidden"} id="internal">
                        <FetchUsers /> 
                        </div> */}
                        <div className={openUserTab === 5 ? "block" : "hidden"} id="active">
                        <FetchActiveUsers /> 
                        </div>
                        <div className={openUserTab === 6 ? "block" : "hidden"} id="inactive">
                        <FetchInactiveUsers /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTab;