import React, { useState } from 'react'
import { useFormik } from 'formik'
import axiosInstance from '../../axiosConfig';
import { toast } from 'react-toastify'
import { storage } from '../../setupFirebase'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import Placeholder from '../../images/placeholder.png'
import uuid from 'react-uuid';
 
const CategoryModal = ({visible, handleCloseButton, modalData, func, selectedImage}) => {

    const [percent, setPercent] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [_, setImage] = useState(null);
    const id = modalData.id;
    
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: modalData.name,
            description: modalData.description,
            imageUrl: '',
            imagePath: '/docs/user',
        },
    
        onSubmit: (values, { resetForm }) => {
            values.imagePath = modalData.imagePath ?? `/dashboard/categories/${modalData.name}-${uuid()}`;
        
            const storageRef = ref(storage, values.imagePath);
            const uploadTask = uploadBytesResumable(storageRef, values.imageUrl)
            
            // This is an update
            // Should check if Firebase has the url then delete and add the new one
            uploadTask.on(
                "state_changed", 
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );  
                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                        values.imageUrl = url

                        const uri = `/services/categories/${id}`;
                        const params = JSON.stringify(values);
                        const headers = {
                            'Content-Type': 'application/json'
                        };
                        axiosInstance.patch(uri, params, { headers })
                        .then(() => {
                            toast.success('Update successful!', { 
                                autoClose: 4000, 
                            })
                            resetForm()
                            func()
                            handleCloseButton()
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    }
                )}
            )
                            console.log(JSON.stringify(values, null, 2));

        }
    })

    return (
        <>
            <div className={`overflow-y-auto ${visible ? 'fixed z-50 inset-0' : 'hidden' }`}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6' aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <p className='text-center font-medium text-lg'>Edit Category</p>      
                        <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                            <div className="mt-3 sm:mt-5">
                                <div className='grid'>
                                    <div className='flex items-center space-x-2 mb-2'>
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-900">Name</label>
                                        {/* {errors.name && touched.name ? (
                                            <span className='text-xs text-red-400 font-light'>* {errors.name}</span>
                                        ) : null} */}
                                    </div>
                                    <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Category name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    />
                                </div> 

                                <div className='mt-4'>
                                    <div className='flex items-center space-x-2 mb-2'>
                                        <label htmlFor="imageUrl" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                    </div>
                                    <input 
                                    type="file"
                                    accept="image/*" 
                                    id="imageUrl" 
                                    name="imageUrl" 
                                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                                    onChange={(event) => {
                                        formik.setFieldValue("imageUrl", event.target.files[0]); 
                                        setImage(URL.createObjectURL(event.target.files[0]))
                                    }}
                                    />
                                    {percent === 0? 
                                    "" :
                                    <div className='flex space-x-2 w-full'>
                                        <div className="bg-gray-200 rounded-full h-1 mt-2 w-full">
                                            <div className="bg-blue-600 h-1 rounded-full" style={{"width": `${percent}%`}}></div>
                                        </div>
                                        <p className='text-xs font-normal text-blue-600 flex'>{percent} <span>%</span></p>
                                    </div>
                                    }

                                    { selectedImage &&  
                                    <img alt="category_image" className="w-full h-48 object-center object-cover mt-2" src={selectedImage ? selectedImage : Placeholder} />
                                    }
                                  
                                </div>
                                <div className="mt-4">
                                   <div className='flex items-center space-x-2 mb-2'>
                                        <label htmlFor="description" className="block text-sm font-medium text-gray-900">Description</label>
                                   </div>
                                    <textarea 
                                    id="description" 
                                    name="description" 
                                    rows="4" 
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Description" 
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">      
                                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={() => handleCloseButton()}>Cancel</button>
                    
                                <button 
                                type="submit" 
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm cursor-pointer" 
                                >
                                Update</button>
                            </div>
                        </form>                    
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(CategoryModal)