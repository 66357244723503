import React from 'react';

function SnapshotCard({time, amount, orders}) {

  return (
        <div className="max-w-md bg-white shadow-lg rounded-xl border border-slate-200 p-5">
           <div className='flex space-x-3 items-center'>
                <div class="grid justify-center">
                    <div className='grid justify-center py-2 h-9 w-9 rounded-full bg-red-600 bg-opacity-20'>
                        <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                    </div>
                </div>
                <h3 className='text-gray-900 text-opacity-90 text-sm font-semibold'>{time}</h3>
           </div>
           <div className='pt-2'>
               <p className='text-lg font-semibold text-gray-900'>Ksh {amount}</p>
           </div>
           <div className='flex items-baseline justify-between'>
               <p className='text-xs text-light text-gray-400'>{orders} orders</p>
               <svg className="w-6 h-6 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
                </svg>
           </div>       
        </div>
  );
}

export default React.memo(SnapshotCard);
