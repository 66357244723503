import React from 'react'
import ReviewsCard from '../users/ReviewsCard'

const UserModal = ({isOpen, handleCloseButton, name, email, phoneNumber, active, bio, avatar, userType, businessName, locationPro, ratings}) => {
console.log('ratings', ratings);
  // Modal Body
  const modalBody = () => 
  <> 
  <div className="w-full bg-white rounded-lg shadow-md px-5">
      <div className="flex flex-col pt-5 pb-10">
        <img className="mb-3 w-24 h-24 rounded-full shadow-lg object-center object-cover" src={avatar} alt="avatar"/>
        <h5 className="text-xl font-medium text-blue-600">{name}</h5>
        <span className="text-sm text-gray-500">{email}</span>
        <div className='mt-4'>
          <p className='text-sm text-gray-600'><span className='text-bold text-black'>Bio:</span> {bio ? bio : 'No bio'}</p>
        </div>
        <div className='flex space-x-12 items-center mt-5'>
          <p className='text-sm text-gray-600'><span className='text-bold text-black'>Phone:</span> {phoneNumber}</p>
          <p className='text-sm text-gray-600 capitalize'><span className='text-bold text-black'>User Type:</span> {userType}</p>

          {active === true ? 
            <button className='text-green-500 text-xs font-normal py-1 px-4 rounded-full bg-green-100 cursor-default'>
              Active
            </button>
          :
            <button className='text-red-500 text-xs font-normal py-1 px-4 rounded-full bg-red-100 cursor-default'>
              Inactive
            </button>
          }
        </div>
        <div className="mt-5">
          {userType === "professional" ? (
            <div>
              <h2 className='text-base text-gray-900 mb-1'>Professional Details</h2>
              <p className='text-sm text-gray-600'><span className='text-bold text-black'>Business Name:</span> {businessName}</p>
              <p className='text-sm text-gray-600'><span className='text-bold text-black'>Location:</span> {locationPro}</p>
            </div>
          ) : ''}
        </div>
        <div>
          <h2 className='mb-3 mt-4 text-base text-gray-900'>Reviews</h2>
          <div className='divide-y divide-gray-300'>
            {ratings?.length > 0 ?

            (ratings.map(rate => {
              return (
                <ReviewsCard rate={rate}/>
              )
            })) 
            :
            <p className='text-sm text-gray-600 -mt-2'>No Reviews</p>
            }
          </div>
        </div>
      </div>
  </div>
  </>

  // Modal Back Button
  const modalButtons = () =>  
  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
    <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-blue-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={handleCloseButton}>Back</button>
  </div>


  return (
    <div className={`overflow-y-auto ${isOpen ? 'fixed z-50 inset-0' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            {modalBody()}
          </div>
          {modalButtons()}
        </div>
      </div> 
    </div>   
  )
}

export default UserModal