import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import axiosInstance from '../../axiosConfig';
import * as Yup from 'yup'
import { storage } from '../../setupFirebase'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';

const AddCategory = ({visible, handleCloseButton, func, handleVisible}) => {

    const [percent, setPercent] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [_, setImage] = useState(null);

    const addCategorySchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string().required('Description is required'),
    });

    return (
        <>
            <div className={`overflow-y-auto ${visible ? 'fixed z-50 inset-0' : 'hidden' }`}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6' aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    

                        <Formik
                        initialValues = {{ 
                            name: '', 
                            description: '',  
                            imageUrl: "",
                            imagePath: '/docs/user',
                        }}

                        validationSchema = {addCategorySchema}

                        onSubmit = {(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false); 
                            // console.log(JSON.stringify(values, null, 2));
                            const storageRef = ref(storage, `/dashboard/categories/${values.name}-${uuid()}`);

                            const uploadTask = uploadBytesResumable(storageRef, values.imageUrl)

                            uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const percent = Math.round(
                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                );  
                                // update progress
                                setPercent(percent);
                            },
                            (err) => console.log(err),
                            () => {
                                // download url
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    // console.log(url);
                                    values.imageUrl = url

                                    const params = JSON.stringify(values);
                                    const headers = {
                                        'Content-Type': 'application/json',
                                    };

                                    axiosInstance.post('/services/categories', params, { headers })
                                    .then(() => {
                                        toast.success("Category created successfully!", {
                                            className: 'text-sm font-medium text-gray-800'
                                        });
                                        resetForm()
                                        func()
                                        handleVisible(false)
                                    })
                                    .catch(error => {               
                                        if (error.response){
                                            console.log('Response', error.response)          
                                        }else if(error.request){
                                            console.log('Request', error.request)
                                        }else if(error.message){
                                            console.log('Message', error.message)
                                        }
                                    })
                                });
                            });

                           
                            // const fd = new FormData()
                            // fd.append('imageUrl', values.imageUrl, values.imageUrl.name);

                            // console.log('CatData', values)

                   
                        }}
                    >

                    {({ values,
                        setFieldValue,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting, 
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="mt-3 sm:mt-5">
                                <div className='grid'>
                                    <div className='flex items-center space-x-2 mb-2'>
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-900">Name</label>
                                        {errors.name && touched.name ? (
                                            <span className='text-xs text-red-400 font-light'>* {errors.name}</span>
                                        ) : null}
                                    </div>
                                    <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Category name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    />
                                </div>               
                                <div className='mt-4'>
                                    <div className='flex items-center space-x-2 mb-2'>
                                        <label htmlFor="imageUrl" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                    </div>
                                    <input 
                                    type="file"
                                    accept="image/*"
                                    id="imageUrl" 
                                    name="imageUrl" 
                                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                                    onChange={(event) => { setFieldValue("imageUrl", event.target.files[0]); setImage(URL.createObjectURL(event.target.files[0])) }}                  
                                    />
                                   
                                    {percent === 0? 
                                    "" :
                                    <div className='flex space-x-2 w-full'>
                                        <div className="bg-gray-200 rounded-full h-1 mt-2 w-full">
                                            <div className="bg-blue-600 h-1 rounded-full" style={{"width": `${percent}%`}}></div>
                                        </div>
                                        <p className='text-xs font-normal text-blue-600 flex'>{percent} <span>%</span></p>
                                    </div>
                                    }
                                </div>
                                <div className="mt-4">
                                    <div className='flex items-center space-x-2 mb-2'>
                                            <label htmlFor="description" className="block text-sm font-medium text-gray-900">Description</label>
                                            {errors.description && touched.description ? (
                                                <span className='text-xs text-red-400 font-light'>* {errors.description}</span>
                                            ) : null}
                                    </div>
                                    <textarea 
                                    id="description" 
                                    name="description" 
                                    rows="4" 
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Description" 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">      
                                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={() => handleCloseButton()}>Cancel</button>
                    
                                <button 
                                type="submit" 
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm cursor-pointer" 
                                disabled={isSubmitting}>
                                Submit</button>
                            </div>
                        </Form>
                        )}
                        </Formik> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(AddCategory)