import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from './setupFirebase'

import './css/style.scss';

import './charts/ChartjsConfig';
import SessionTimeout from './SessionTimeout';

// Import pages
import Dashboard from './pages/Dashboard';
import Orders from './pages/Orders';
import Revenues from './pages/Revenues';
import Users from './pages/Users';
import Applications from './pages/Applications';
import Messaging from './pages/Messaging';
import Settings from './pages/Settings';
import OrderDetails from './pages/OrderDetails';
import ApplicationDetails from './pages/ApplicationDetails';
import Categories from './pages/Categories';
import Services from './pages/Services';
import Login from './Login';

import { useDispatch } from 'react-redux';
import { getToken } from './features/user/tokenSlice'
import { Toaster } from 'react-hot-toast';

function App() {
 
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();

// authentication
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState('')

  const [loggedInUser, setLoggedInUser] = useState(null) 

  useEffect(() => {
     onAuthStateChanged(auth, (currentUser) => {
      setLoggedInUser(currentUser)
    })
  }, []);

  const login = async () => {
    try {
      setLoading('Checking credentials...')
      setError(null)
      const User = await signInWithEmailAndPassword(auth, loginEmail, loginPassword)  
      // console.log("User", User)
      setLoading(null)
      dispatch(getToken(User.user.auth.currentUser.accessToken))
      sessionStorage.setItem('Auth Token', User.user.auth.currentUser.accessToken)
      navigate('/')
    } catch (error) {
      setLoading(null)
      setError('No user with these credentials was found!')
    }
  }

  const logout = async () => {
    signOut(auth)
    dispatch(getToken(null))
    sessionStorage.removeItem('Auth Token')
  }
  // End of authentication

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

const authToken = sessionStorage.getItem('Auth Token');

  return (
    <>
    {/* Use token to check if user is logged in instead of the authenticated user --- try this -- fetch it from the localStorage (Auth Token) */}
      { authToken == null ? 
       ( <div>
          <Login handleLoginEmail={(e) => {setLoginEmail(e.target.value)}} handleLoginPassword={(e) => {setLoginPassword(e.target.value)}} login={() => {login()}} message={error} loading={loading}/>
        </div>)
      :  
       <>
       <Toaster />
        <Routes>
          <Route exact path="/" element={<Dashboard loggedUser={loggedInUser?.email} logout={logout}/>} />
          <Route exact path="/orders" element={<Orders loggedUser={loggedInUser} logout={logout}/>} />
          <Route exact path="/orders/:id" element={<OrderDetails loggedUser={loggedInUser?.email} logout={logout}/>} />
          
          <Route exact path="/revenues" element={<Revenues loggedUser={loggedInUser?.email} logout={logout}/>} />   
          <Route exact path="/users" element={<Users loggedUser={loggedInUser?.email} logout={logout}/>} />   
          <Route exact path="/applications" element={<Applications loggedUser={loggedInUser?.email} logout={logout}/>} />   
          <Route exact path="/messaging" element={<Messaging />} />    
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/applications/:id" element={<ApplicationDetails loggedUser={loggedInUser?.email} logout={logout}/>} />
          <Route exact path="/categories" element={<Categories loggedUser={loggedInUser?.email} logout={logout}/>} />
          <Route exact path="/services" element={<Services loggedUser={loggedInUser?.email} logout={logout}/>} />
        </Routes>
        <SessionTimeout />
       </>
      }
      
    </>
  );
}

export default React.memo(App);
