import React from 'react'
import logo from './images/TausiLogo-dark.png'
import coverImg from './images/tausi-girl.avif'

const Login = ({login, handleLoginEmail, handleLoginPassword, message, loading}) => {
  return (
    <div className='grid grid-cols-1 gap-10 sm:gap-0 sm:grid-cols-2 h-screen bg-gray-100'>

      <div className='order-2 sm:order-1'>
        <img className='w-full h-screen object-center object-cover sm:rounded-br-full' src={coverImg} alt="coverImg" />
      </div>

      <div className='grid justify-center items-center mt-10 sm:mt-0 order-1 sm:order-2 pb-10 sm:pb-0'>
        <div className='w-96 py-10 rounded-lg bg-white shadow-2xl'>
          <div className='grid justify-center'>
            <img className='object-cover object-center w-40 h-20' src={logo} alt="logo" /> 
          </div>

          <h2 className='text-2xl font-bold text-center mt-7 text-gray-900 text-opacity-80'>Login To Your Account</h2>

          <p className='text-xs text-gray-700 font-medium text-center mt-3 px-10'>Enter Your Email Address and Password To Access Admin Panel</p>
          <div className='flex justify-center'>
            <p className='text-xs pt-2 text-red-500 font-light text-center'>{message}</p>
            <p className='text-xs pt-2 text-blue-500 font-light text-center'>{loading}</p>
          </div>

          <form className='mt-4'>
            <div className='px-7'>
              <label htmlFor="email" className="sr-only">Email</label>
              <input type="email" name="email" id="email" className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Email" required onChange={handleLoginEmail}/>
            </div>
            <div className='px-7 mt-5'>
              <label htmlFor="password" className="sr-only">Password</label>
              <input type="password" name="password" id="password" className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Password" required onChange={handleLoginPassword}/>
            </div>
            <div className="flex items-center mt-4 mb-4 px-7">
                <input id="checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 shadow border-gray-300 rounded focus:ring-1 focus:ring-blue-500" />
                <label htmlFor="checkbox" className="ml-2 text-xs font-medium text-gray-600">Remember me</label>
            </div>

           <div className='px-7'>
            <button type="button" className="text-white bg-blue-500 w-full hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm mt-5 px-5 py-2.5 mb-2 focus:outline-none" onClick={login}>Login</button>
           </div>
          </form>

          <p className='text-xs font-medium mt-3 text-center text-gray-500'>Forgot your password?</p>
        </div>
      </div>
    </div>
  )
}

export default Login