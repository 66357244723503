import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import moment from 'moment';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from './features/user/tokenSlice';
// import { toast } from 'react-toastify';
import { getSeconds } from './features/seconds/secondsSlice';
import toast from 'react-hot-toast';
import { FiAlertTriangle } from 'react-icons/fi';

const SessionTimeout = () => {
    const [events] = useState(['click', 'load', 'scroll']);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const dispatch = useDispatch();
    const seconds = useSelector((state) => state.seconds.value);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if(user) {
            setIsAuthenticated(true);
        }else {
            setIsAuthenticated(false);
        }
    });

    let timeStamp;
    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();

    // start inactive check
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let timeChecker = (() => {
        startTimerInterval.current = setTimeout(() => {
        let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
        warningInactive(storedTimeStamp);
        }, 1000);
    });

    // warning timer
    let warningInactive = (timeString) => {
        clearTimeout(startTimerInterval.current);

        warningInactiveInterval.current = setInterval(() => {
            const maxTime = 5;
            const popTime = 1;

            const diff = moment.duration(moment().diff(moment(timeString)));
            const minPast = diff.minutes();
            const leftSecond = 60 - diff.seconds();

            if (minPast === popTime) {
                dispatch(getSeconds(leftSecond));
            }

            if (minPast === maxTime) {
                clearInterval(warningInactiveInterval.current);
                sessionStorage.removeItem('lastTimeStamp');
                signOut(auth);
                sessionStorage.removeItem('Auth Token');
                dispatch(getToken(null));
                 dispatch(getSeconds(0));
            }

        }, 1000);
    };

    // reset interval timer
    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        if (isAuthenticated) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            timeStamp = moment();
            sessionStorage.setItem('lastTimeStamp', timeStamp);
        } else {
            clearInterval(warningInactiveInterval.current);
            sessionStorage.removeItem('lastTimeStamp');
        }
        timeChecker();
    }, [isAuthenticated]);

    useEffect(() => {
        events.forEach((event) => {
        window.addEventListener(event, resetTimer);
        });

        timeChecker();

        return () => {
        clearTimeout(startTimerInterval.current);
        //   resetTimer();
        };
    }, [resetTimer, events, timeChecker]);

    // console.log("seconds", seconds);
    
    if(seconds === 5) {
        toast('You will logged out due to inactivity on the dashboard.', {
            className: 'w-lg',
            duration: 4000,
            position: 'top-right',

            style: {
                border: '1px solid black',
                fontSize: '14px',
            },

           icon: <FiAlertTriangle className='w-10 h-10 text-red-500' />,

            // Aria
            ariaProps: {
                role: 'status',
                'aria-live': 'polite',
            },
        })
    }

    // change fragment to modal and handleclose func to close
    return <Fragment />;
};

export default React.memo(SessionTimeout);