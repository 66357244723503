import React from 'react'
import { saveAs } from 'file-saver'

const Resume = ({ resume }) => {

  const saveFile = () => {
    saveAs(
      resume,
      "ApplicantResume.pdf"
    );
  }

  return (
    <div className='flex space-x-4 items-center pt-2'>
        <div className='border border-gray-300 rounded-xl p-4'>
           <div className='w-20 h-20 bg-gray-200 grid justify-center items-center rounded-full'>
                <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
           </div>
        </div>
        <div>
           {
            resume ? 
            <button className='text-sm font-normal text-white bg-blue-500 hover:bg-blue-700 px-4 py-1 rounded-full' onClick={saveFile}>Open to view</button>
            :
            <p className='text-red-500 text-sm font-normal'>No resume attached</p>
           }
        </div>
    </div>
  )
}

export default Resume