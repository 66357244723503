import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const servicesApi = createApi({
    reducersPath: "services",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.tausiapp.com/api/v2",
        prepareHeaders: (headers) => {
            const token = sessionStorage.getItem('Auth Token');
            if(token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    tagTypes: ['Service'],
    endpoints: (builder) => ({
        getServices: builder.query({
            query: () => `services?limit=1000000000`,
            providesTags: ['Service'],
        }),
        deleteService: builder.mutation({
            query: serviceId => ({
                url: `services/${serviceId}`,
                method: 'DELETE',
                credentials: 'include',
            }),
            invalidatesTags: ['Service'],
        })
    })
})

export const { useGetServicesQuery, useDeleteServiceMutation } = servicesApi;
