import React from 'react'
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const ServiceList = ({ services = [] }) => {
    // console.log('services================', services);
    return (
        <div className="divide-y divide-gray-300"> 
           { services.length > 0 ? (
                services.map((service ) => (
                <div className='flex items-center space-x-5 py-2'>
                    <img className='object-center object-cover w-10 h-10 rounded-full' src={service.serviceData.imageUrl} alt=''/>
                    <p className='text-sm font-medium text-gray-800 pr-10'>{service.serviceData.name}</p>
    
                    <div className='flex space-x-1 items-center'>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"></path>
                        </svg>
                        <p className='text-sm font-medium text-gray-800 pr-8'>Ksh {service.price}/-</p>
                    </div>
    
                    <div className='flex space-x-0.5 items-center'>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <p className='text-sm font-semibold text-gray-800'>{dayjs.duration(service.duration).asHours()} hours</p>
                    </div>
                </div>
                )) 
           ) : (
                <div className='flex items-center space-x-5 py-4'>
                    <p className='text-sm font-medium text-gray-800'>No services</p>
                </div>
           )}
        </div>
    )
}

ServiceList.propTypes = {
    services: PropTypes.array.isRequired
}

export default ServiceList