import React, { useState, useMemo, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import Skeleton from '@mui/material/Skeleton';

function TausiDashCard01() {

  const [data, setData] = useState(useMemo(() => [], []));
  const [loading, setLoading] = useState(true);

  const fetchTotalOrders = async () => {
    await axiosInstance.get('dashboard/home/stats')
    .then(response => {
        setData(response.data.data);
        setLoading(false);
    })
    .catch(error => {
      setLoading(false);
      console.log(error);
    })
  }

  useEffect(() => {
    fetchTotalOrders();
  }, [])

  return (
      <div className="grid grid-cols-1 md:grid-cols-2 grid-flow justify-center col-span-full bg-white shadow-lg rounded-xl border border-slate-200 p-5">
        {/* Client orders */}
        <div className="grid grid-cols-2 items-center p-3 border-b md:border-r md:border-b border-gray-300 ">
          <div>
            {/* <p className="text-lg font-bold text-black">342 <span className="text-xs font-light text-green-500">+0.5%</span></p> */}
            <p className="text-lg font-bold text-black">{loading ? <Skeleton variant="text" width={30}/> : data?.appointments}</p>
            <p className="pt-1 text-gray-600 font-normal text-xs tracking-wide">Client orders</p>
          </div> 
          {/* <div className='grid justify-center'>
            <svg className="w-12 h-12 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
          </div>     */}
        </div>

        {/* App users */}   
        <div className="grid grid-cols-2 items-center p-3 border-b border-gray-300">
          <div className="grid justify-start ml-1">
            <p className="text-lg font-bold text-black">{loading ? <Skeleton variant="text" width={30}/> : data?.users}</p>
            <p className="pt-1 text-gray-600 font-normal text-xs tracking-wide">App Users</p>
          </div>
          {/* <div className='grid justify-center'>
            <svg className="w-12 h-12 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
          </div>     */}
        </div>

        {/* Revenue */} 
        <div className="grid grid-cols-2 items-center p-3  md:border-r border-gray-300">
          <div className="grid justify-start">
            {/* <p className="text-lg font-bold text-black">$ 1,567 <span className="text-xs font-light text-red-500">-2%</span></p> */}
            <p className="text-lg font-bold text-black">{loading ? <Skeleton variant="text" width={30}/> : ('Ksh ' + data?.revenue)}</p>
            <p className="pt-1 text-gray-600 font-normal text-xs tracking-wide">Revenue</p>
          </div>
          {/* <div className='grid justify-center'>
            <svg className="w-12 h-12 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"></path>
            </svg>  
          </div>     */}
        </div>

        {/* New applications */} 
        <div className="grid grid-cols-2 items-center p-3 border-t md:border-0 border-gray-300">
          <div className="grid justify-start ml-1">
            <p className="text-lg font-bold text-black">{loading ? <Skeleton variant="text" width={30}/> : data?.newApplications}</p>
            <p className="pt-1 text-gray-600 font-normal text-xs tracking-wide">New Applicants</p>
          </div>
          {/* <div className='grid justify-center'>
            <svg className="w-12 h-12 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"></path>
            </svg>
          </div>     */}
        </div>
      </div>
  );
}

export default TausiDashCard01;
