import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../axiosConfig';
import { useParams } from 'react-router';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import OrderDetailsCard from '../partials/orders/OrderDetails';
import ClientCard from '../partials/orders/ClientCard';
import ServiceProviderCard from '../partials/orders/ServiceProvider';
import PaymentCard from '../partials/orders/PaymentCard';

function OrderDetails({loggedUser, logout}) {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  let { id } = useParams();
  const [data, setData] = useState(useMemo(() => [], []));
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [balance, setBalance] = useState(0)

  const fetchOrder = async () => {
      await axiosInstance.get(`/appointments/${id}`)
      .then(response => {
          setData(response.data.data)

          let isoDate = response.data.data.createdAt
          setDate(new Date(isoDate).toDateString())

          let t = new Date(isoDate).toLocaleTimeString('en', { timeStyle: 'short', hour12: false, timeZone: 'UTC' });
          setTime(t)
          // Balance
          let diff = response.data.data.totalPrice - response.data.data.amountUpfront
          setBalance(diff) 
      })
      .catch(err => {
          console.log(err)
      })
  };

  useEffect(() => {
      fetchOrder();
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
      <div className="flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} title='Order Details' loggedUser={loggedUser} logout={logout}/>

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-gray-100">
              {/* Cards */}
              <div className="grid grid-cols-12 gap-6 mb-10">
                {/* Grid 1 */}
                <div className="col-span-full sm:col-span-5 space-y-6">
                  {/* Order details  Card */}
                  <OrderDetailsCard data={data} orderingDate={date} startingDate={time}/>
                  {/* Client Card */}
                  <ClientCard data={data}/>    
                </div>

                {/* Grid 2 */}
                <div className="col-span-full sm:col-span-7 space-y-6">
                  {/* Service provider card */}
                  <ServiceProviderCard data={data}/>
                  {/* Payment Card */}
                  <PaymentCard data={data} balance={balance}/>
                </div>       
              </div>
            </div>
          </main>

        </div>
      </div>
  );
}

export default React.memo(OrderDetails);