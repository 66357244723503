import React, { useState, useMemo, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import { NavLink } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

// Import utilities
import OngoingOrder from '../Tausi/OngoingOrder';

function TausiDashCard03() {

  const [orders, setOrders] = useState(useMemo(() => [], []));
  const [loading, setLoading] = useState(true);

  const fetchOngoingOrders = async () => {
    await axiosInstance.get('/appointments?limit=1000000')
    .then(response => {
      const allOrders = response.data.data;

      const ongoingOrders = allOrders.filter(ongoingOrder => ongoingOrder.status === 'ongoing');

      // Filter Orders less than 30 mins ago
      const filteredOrders = ongoingOrders.filter(order => new Date(order.createdAt).getMinutes() < 30);

      // Sort in Ascending order
      const sortedOrders = filteredOrders.sort(function(a, b) {
        const dateA = new Date(a.createdAt).getMinutes()
        const dateB = new Date(b.createdAt).getMinutes()
        return dateB - dateA
      });

      const orders = sortedOrders.splice(0, 5);
      setOrders(orders);
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    })
  };

  useEffect(() => {
    fetchOngoingOrders();
  }, []);

  return (
    <>
      {loading ? 
      (
        <div className="grid gap-6 col-span-full bg-white shadow-lg rounded-lg border border-slate-200 p-5">
          {/* Header */}
          <div className="flex justify-between">
            <h4 className="text-lg font-semibold text-black">Ongoing Orders</h4>
            <div className="flex space-x-3 items-center">
              <button className="text-xs text-red-500 px-5 py-1 bg-pink-500 bg-opacity-30 rounded-full">Newest</button>
            </div>
          </div>
          <div className="flex space-x-4">
            <Skeleton variant="circular" width={60} height={60} />
            <div>
              <Skeleton variant="text"  width={300} />
              <Skeleton variant="text"  width={300} />
            </div>
          </div>
          <div className="flex space-x-4">
            <Skeleton variant="circular" width={60} height={60} />
            <div>
              <Skeleton variant="text"  width={300} />
              <Skeleton variant="text"  width={300} />
            </div>
          </div>
          <div className="flex space-x-4">
            <Skeleton variant="circular" width={60} height={60} />
            <div>
              <Skeleton variant="text"  width={300} />
              <Skeleton variant="text"  width={300} />
            </div>
          </div>
          <div className="flex space-x-4">
            <Skeleton variant="circular" width={60} height={60} />
            <div>
              <Skeleton variant="text"  width={300} />
              <Skeleton variant="text"  width={300} />
            </div>
          </div>
          <div className="flex space-x-4">
            <Skeleton variant="circular" width={60} height={60} />
            <div>
              <Skeleton variant="text"  width={300} />
              <Skeleton variant="text"  width={300} />
            </div>
          </div>
        </div>
      )
      : (
      <div className="grid col-span-full bg-white shadow-lg rounded-lg border border-slate-200 p-5">
        {/* Header */}
        <div className="flex justify-between">
          <h4 className="text-lg font-semibold text-black">Ongoing Orders</h4>
          <div className="flex space-x-3 items-center">
            <button className="text-xs text-red-500 px-5 py-1 bg-pink-500 bg-opacity-30 rounded-full">Newest</button>
          </div>
        </div>

        {/* Body */}
       <div className="pt-6 pb-3">    
        <ol className="relative border-l border-gray-200 divide-y divide-gray-200">
          {orders.map((order, index) => {
            // console.log(order.client.profilePictureUrl);
            return  <OngoingOrder 
            name={order.professional?.businessName} 
            img={order.client.profilePictureUrl} 
            location={order.professional?.locationAddress} 
            minutes={new Date(order.createdAt).toDateString()} 
            key={index}
            />
          })}
        </ol>
        {/* View more  */}
        <NavLink end to="/orders" className="block">
          <div className="flex justify-center pt-7">
            <button type="button" className="py-1 px-5 text-xs text-red-600 font-semibold focus:outline-none bg-white rounded-full border border-red-400 hover:bg-gray-100 hover:text-red-500 focus:z-10 focus:ring-1 focus:ring-gray-200">All Orders</button>
          </div>
        </NavLink>
       </div>
      </div>
      )}   
    </>
  );
}

export default TausiDashCard03;
