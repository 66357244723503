import React, { useEffect, useState, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router';
import axiosInstance from '../axiosConfig';
import Table3 from './Table3';


function FetchAllApplicants() {

   const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: 'user.name',
            id: 'Name',
        },
        {
            Header: "Business Name",
            accessor: 'businessName',
            id: 'Business Name',
        },
        {
            Header: "PhoneNumber",
            accessor: 'user.phoneNumber',
            id: 'Phone Number',
        },
        {
            Header: "Application date",
            accessor: 'createdAt',
            id: 'Application Date',
            Cell: ({ row }) => {
                let date = row.original.createdAt
                return new Date(date).toDateString()
            }
        },
        {
            Header: "Location",
            accessor: 'locationAddress',
            id: 'Location',
        },
        // {
        //     Header: "Status",
        //     accessor: 'verificationStatus',
        //     id: 'Status',
        //     Cell: ({ row }) => {
        //         const status = row.original.verificationStatus;
        //         if(status === 'pending') {
        //             return (<button className='text-xs py-1 px-4 bg-indigo-100 rounded-full text-indigo-500 cursor-default font-normal'>Pending</button>)
        //         }else if(status === 'review') {
        //             return (<button className='text-xs py-1 px-4 bg-green-100 rounded-full text-green-500 cursor-default font-normal'>In Review</button>)
        //         }else {
        //             return (<button className='text-xs py-1 px-4 bg-red-100 rounded-full text-red-500 cursor-default font-normal'>Rejected</button>)
        //         }
        //     },
        // },
        {
            Header: "Action",
            id: "action",
            Cell: ({ row }) => (
                <button onClick={e => handleButtonClick(e, row)} className='text-white hover:bg-blue-600 text-xs font-medium py-1 px-4 rounded-full bg-blue-400'>
                    View
                </button>
            )
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    const [applicantsData, setApplicantsData] = useState(useMemo(() => [], []));

    const fetchData = async () => {
        await axiosInstance.get('/professionals?limit=100000000')
        .then(response => {
            const applicants = response.data.data;
            // console.log(applicants);
            setApplicantsData(applicants);
        })
        .catch(error => {
            console.log(error)
        })
    };

    useEffect(() => {
        const ac = new AbortController();
        fetchData();
        return () => ac.abort();
    }, []);

    let navigate = useNavigate();

    const handleButtonClick = (e, row) => {
        let applicantsId = row.original.id;
        navigate(`/applications/query?id=${applicantsId}`)
    };

    return (
        <>
            <Table3 columns={columns} data={applicantsData} csvData={applicantsData} fileName='applications'/>
        </>
    )
}

export default FetchAllApplicants;