import React, { useEffect, useState, useMemo} from 'react';
import axiosInstance from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from "react-redux";
// import { selectOrders } from '../features/orders/orderSlice';

// import { useGetOrdersQuery } from '../services/orders';
 
import Table5 from './Table5';

function FetchOrders() {
    // Orders List state
    // const ordersList = useSelector(selectOrders);
    // console.log(ordersList);

    const columns = useMemo(() => [
        {
            Header: "Order By",
            accessor: 'client.name',
            id: 'Order By'
        },
        {
            Header: "Service Ordered",
            accessor: 'service.serviceData.name',
            id: 'Service Ordered',
        },
        {
            Header: "Provider",
            accessor: 'professional.businessName',
            id: 'Price',
        },
        {
            Header: "Order Date/Time",
            accessor: 'time.date',
            id: 'Order Date',
            Cell: ({ row }) => {
                let ordereddate = row.original.createdAt
                let converted = new Date(ordereddate)
                let date = converted.toDateString()
                let time = converted.getHours() + ':' + converted.getMinutes().toString().padStart(2, "0");
                return (
                    <span className='text-sm mr-5'>{date}, {time}</span>
                )
            }
        },
        {
            Header: "Status",
            accessor: d => d.status.toString(),
            Cell: ({ row }) => {
                const orderStatus = row.original.status;
              
               if( orderStatus === "pending") {
                    return <button className='text-xs py-1 px-4 bg-orange-100 rounded-full text-orange-500 cursor-default font-normal'>Pending</button> 
                } else if( orderStatus === "cancelled") {
                    return <button className='text-xs py-1 px-4 bg-red-100 rounded-full text-red-500 cursor-default font-normal'>Cancelled</button> 
                } else if( orderStatus === "ongoing") {
                    return <button className='text-xs py-1 px-4 bg-blue-100 rounded-full text-blue-500 cursor-default font-normal'>Ongoing</button>
                } else if( orderStatus === "scheduled") {
                    return <button className='text-xs py-1 px-4 bg-teal-100 rounded-full text-teal-500 cursor-default font-normal'>Scheduled</button>
                }else {
                    return <button className='text-xs py-1 px-4 bg-green-100 rounded-full text-green-500 cursor-default font-normal'>Completed</button>
                }
            }
        },
        {
            Header: "Action",
            id: "Action",
            Cell: ({ row }) => (
              <button className='text-white hover:bg-blue-600 text-xs font-medium py-1 px-4 rounded-full bg-blue-400' onClick={ () => handleButtonClick(row)}>
                View
              </button>
            )
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    // const { data: orders, isLoading, refetch } = useGetOrdersQuery();


    const [ordersData, setOrdersData] = useState([]);
    const [length, setLength] = useState(0)
    const [loading, setLoading] = useState(true);

    // const fetchOrders = useCallback(() => {
    //     const dataOrders = orders?.data
    //     setOrdersData(dataOrders);
    //     setLength(dataOrders.length);
    //     setLoading(isLoading);
    // }, [orders.data, isLoading])
    
    // const mountedRef = useRef(true);

    // useEffect(() => {
    //     if(mountedRef.current === true) {
    //         fetchOrders();
    //         refetch();
    //     }
    //   return () => { mountedRef.current = false }
    // }, []);

     const fetchData = async () => {
        await axiosInstance.get('/appointments/?limit=10000000')
        .then(response => {
            const { data } = response.data;
            setOrdersData(data);
            setLength(data.length)
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
    };

    useEffect(() => {
        const ac = new AbortController();
        fetchData();
        return () => ac.abort();
    }, []);

    const navigate = useNavigate();
    
    const handleButtonClick = (row) => {
        navigate(`${row.original.id}`);
    };

    return (
        <>
            <Table5 columns={columns} data={ordersData} csvData={ordersData} fileName='orders' isLoading={loading} total={length}/>
        </>
    )
}

export default React.memo(FetchOrders);