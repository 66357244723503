import React from 'react';
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai';

function ServiceProviderCard({ data }) {

// console.log(data.metaData?.professionalRating);
    const serviceProviderData = data.professional;

    // Business Name
    const name = serviceProviderData?.businessName;
    // Location
    const locationAddress = serviceProviderData?.locationAddress;
    // User name
    const providerName = serviceProviderData?.user.name;
    // User email
    const providerEmail = serviceProviderData?.user.email;
    // Provider
    const avatar = serviceProviderData?.user.profilePictureUrl;
    // Rating
    const rating = data.metaData?.professionalRating;
    // Completed Orders
    const completedOrders = data.metaData?.professionalCompletedAppointmentCount;
    // Cancelled Orders
    const cancelledOrders = data.metaData?.professionalCancelledCount;

  return (
        <div className="col-span-full bg-white shadow-lg rounded-xl border border-slate-200 p-6">
            <h2 className='font-bold text-lg text-gray-900'>Service Provider</h2>

            <div className='grid grid-cols-3 pt-4 pb-5'>
                <div className='col-span-1'>
                    {/* <img className="w-16 md:w-16 h-14 object-center object-cover rounded-tr-2xl rounded-bl-2xl" src={avatar} alt="img"/> */}
                    <img className="w-20 h-20 object-center object-cover rounded-full" src={avatar} alt="img" />
                </div>
              
                <div className="col-span-2 pr-2 -ml-20">
                    <div className="grid grid-cols-3 gap-4 justify-between items-center border-b border-gray-300 pb-5">
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Business Name:</p>
                            <p className='text-sm text-blue-700 font-semibold mt-1'>{ name }</p>
                        </div>
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Location:</p>
                            <p className='text-sm text-blue-700 font-semibold mt-1'>{locationAddress}</p>
                        </div>
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Rating:</p>
                            <div className='mt-2'>
                            {
                            (() => {
                                if (rating === 1) {
                                    return (
                                        <div className='flex space-x-1 items-center'> 
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rating}.0</p>
                                        </div>
                                    )
                                } else if (rating === 2) {
                                    return (
                                        <div className='flex space-x-1 items-center'> 
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rating}.0</p>
                                        </div>
                                    )
                                } else if (rating === 3) {
                                    return (
                                        <div className='flex space-x-1 items-center'> 
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rating}.0</p>
                                        </div>
                                    )
                                } else if (rating === 4) {
                                    return (
                                        <div className='flex space-x-1 items-center'> 
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiOutlineStar />
                                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rating}.0</p>
                                        </div>
                                    )
                                } else if (rating === 5) {
                                    return (
                                        <div className='flex space-x-1 items-center'> 
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <AiTwotoneStar className="text-yellow-500 fill-current"/>
                                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rating}.0</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='flex space-x-1 items-center'> 
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <AiOutlineStar />
                                            <p className='text-xs text-blue-700 font-normal mt-1'>/{rating}.0</p>
                                        </div>
                                    )
                                }
                            })()
                            }
                            </div>
                        </div>
                    </div>

                    <div className='grid grid-cols-3 justify-start gap-4 pt-4 pb-4 border-b border-gray-300'>
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Completed Orders:</p>
                            <p className='text-sm text-blue-700 font-semibold mt-1'>{ completedOrders }</p>
                        </div>
                        
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Cancelled Orders:</p>
                            <p className='text-sm text-blue-700 font-semibold mt-1'>{ cancelledOrders }</p>
                        </div>

                        {/* <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Total Revenue:</p>
                            <p className='text-sm text-blue-700 font-semibold mt-1'>...</p>
                        </div> */}
                    </div>

                    <div className='grid grid-cols-3 justify-start gap-4 pt-4'>
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Provider Name:</p>
                            <p className='text-sm text-blue-700 font-semibold mt-1'>{ providerName }</p>
                        </div>
                        
                        <div className='grid'>
                            <p className='text-sm text-gray-700 font-medium'>Provider Email:</p>
                            <p className='text-sm text-blue-700 font-semibold mt-1'>{ providerEmail }</p>
                        </div>
      
                        {/* <div className="grid justify-center">
                            <div className='grid justify-center py-1 h-8 w-8 rounded-full bg-blue-600'>
                                <svg className="w-6 h-6 text-white fill-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path></svg>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </div>
  );
}

export default React.memo(ServiceProviderCard);
