import React from 'react'
import axiosInstance from '../../axiosConfig'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
 
const AddService = ({visible, handleCloseButton, func, handleVisible}) => {

    const serviceCats = useSelector((state) => state.categories.value);

  return (
    <div className={`overflow-y-auto ${visible ? 'fixed z-50 inset-0' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" >
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                        <div className="mx-auto flex items-center justify-center">
                        <h2 className='text-xl font-medium py-3'>Add New Service</h2>
                    </div>
                    <Formik
                        initialValues={{ 
                            name: '',  
                            minimumPrice: '', 
                            category: '', 
                            description: '', 
                            imageUrl: '',
                            imagePath: '/docs/user',
                        }}

                        validate={values => {
                            const errors = {};
                            if (!values.name) {
                                errors.name = 'Name is required!';
                            } else if (!values.minimumPrice) {
                                errors.minimumPrice = 'Minimum Price is required!';
                            } else if (!values.category) {
                                errors.category = 'Service Category is required!';
                            } else if (!values.description) {
                                errors.description = 'Description is required!';
                            }
                            return errors;
                        }}

                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            // console.log(JSON.stringify(values, null, 2));
                            setSubmitting(false);

                            const params = JSON.stringify(values);
                            const headers = {
                                'Content-Type': 'application/json',
                            };

                            axiosInstance.post('/services', params, { headers })
                            .then(() => {
                                func()
                                toast.success("Service created successfully!", {
                                    className: 'text-sm font-medium text-gray-800'
                                });
                               resetForm()
                               handleVisible(false)
                            })
                            .catch(error => {               
                                if (error.response){
                                    console.log('Response', error.response)          
                                }else if(error.request){
                                    console.log('Request', error.request)
                                }else if(error.message){
                                    console.log('Message', error.message)
                                }
                            })
                        }}
                    >

                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting, }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mt-3 sm:mt-5">
                                <div className='grid'>
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                                    <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Service name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    />
                                    <span className="text-xs text-red-500">{errors.name && touched.name && errors.name}</span>
                                </div>
                                <div className='mt-4'>
                                    <label htmlFor="minimumPrice" className="block mb-2 text-sm font-medium text-gray-900">Minimum Price</label>
                                    <input 
                                    type="number" 
                                    id="minimumPrice" 
                                    name="minimumPrice" 
                                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Minimum Price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.minimumPrice}
                                    />
                                    <span className="text-xs text-red-500">{errors.minimumPrice && touched.minimumPrice && errors.minimumPrice}</span>
                                </div>
                                <div className='mt-4'>
                                    <label htmlFor="categories" className="block mb-2 text-sm font-medium text-gray-900">Category</label>
                                    <select 
                                    id="category" 
                                    name="category" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm sm:text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    >
                                    {serviceCats && serviceCats.map(cat => {
                                        return  <option value={cat.id} key={cat.id} >{cat.name}</option>
                                    })}
                                    </select>
                                    <span className="text-xs text-red-500">{errors.category && touched.category && errors.category}</span>
                                </div>
                                <div className='mt-4'>
                                    <label htmlFor="imageUrl" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                    <input 
                                    type="file" 
                                    id="imageUrl" 
                                    name="imageUrl" 
                                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500" 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.imageUrl}
                                    />
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
                                    <textarea 
                                    id="description" 
                                    name="description" 
                                    rows="4" 
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Description" 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    ></textarea>
                                    <span className="text-xs text-red-500">{errors.description && touched.description && errors.description}</span>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">      
                                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={() => handleCloseButton()}>Cancel</button>
                    
                                <button 
                                type="submit" 
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm cursor-pointer" 
                                disabled={isSubmitting}>
                                Add Service</button>
                            </div>
                        </form>
                        )}
                    </Formik>  
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddService


























// const [name, setName] = useState('')
//     const [servicePrice, setServicePrice] = useState('')
//     const [serviceDuration, setServiceDuration] = useState('')
//     const [description, setDescription] = useState('')
//     const [serviceCategoryId, setServiceCategoryId] = useState('')
//     const [serviceImage, setServiceImage] = useState('')

//     const data = JSON.stringify({
//         name,
//         servicePrice,
//         serviceDuration,
//         description,
//         serviceCategoryId,
//         serviceImage
//     })
//     // console.log(data)

//     const handleSubmit = (data) => {
//         const formData = setData(JSON.stringify(data))
//         axiosInstance.post('/services', {
//             headers: {
//                 'Authorization': `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },
//             formData
//         })
//         .catch(error => {
//             console.log(error)
//         })
//     }