import React from 'react';

function OrderDetailsCard({data, orderingDate, startingDate}) {

    const cat = data.service
    const catData = cat?.serviceData

    return (
        <div className="col-span-full bg-white shadow-lg rounded-xl border border-slate-200 p-6">
            <h2 className='font-bold text-lg text-gray-900'>Order Details</h2>

            <div className='grid grid-cols-2 gap-4 items-center justify-start pt-4 border-b border-gray-300 pb-5'>
                <div className='grid'>
                    <p className='text-sm text-gray-500 font-semibold'>Category:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>{catData?.name}</p>
                </div>
                {/* <div className='grid'>
                    <p className='text-sm text-gray-500 font-semibold'>Location:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>...</p>
                </div> */}
                <div className='grid'>
                    <p className='text-sm text-gray-500 font-semibold'>Order Date:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>{orderingDate}</p>
                </div>
                <div className='grid'>
                    <p className='text-sm text-gray-500 font-semibold'>No. of Clients:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>{data.numberOfClients}</p>
                </div>
            </div>

            <div className='grid grid-cols-2 gap-4 items-center justify-start pt-4 pb-2'>
                <div className='grid'>
                    <p className='text-sm text-gray-500 font-semibold'>Order Status:</p>
                    <div class="w-48">
                       { data.status === "pending" ?
                        <button className='text-xs text-red-600 font-normal mt-1 px-3 py-1 bg-red-100 rounded-full inactive capitalize'>{data.status}</button>
                        :
                        <button className='text-xs text-green-600 font-normal mt-1 px-3 py-1 bg-green-100 rounded-full inactive capitalize'>{data.status}</button>}
                    </div>
                </div>
                <div className='grid'>
                    <p className='text-sm text-gray-500 font-semibold'>Order Time:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>{ startingDate }</p>
                </div>
                <div className='grid'>
                    <p className='text-sm text-gray-500 font-semibold'>Order ID:</p>
                    <p className='text-sm text-blue-700 font-medium mt-1'>{data.id}</p>
                </div>
            </div>
        </div>
  );
}

export default React.memo(OrderDetailsCard);
