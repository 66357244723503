import React, { useState } from 'react'

import ServiceList from './ServiceList';
import ProductList from './ProductList';
import Resume from './Resume';
import { updateVerificationStatus } from '../../api/professional/verification';

import DeclineApplicant from '../modals/DeclineApplicant';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


const ApplicantTab = ({ applicant, services, brands, resume }) => {

    const [openApplicantTab, setOpenApplicantTab] = useState(1);
    const [isOpen, setIsOpen] = useState(false)

    const handleApproveApplication = async (e) => {
        e.preventDefault();
        const dataToSend = {
            verificationStatus: "approved",
            verificationDescription: "Your account has been approved and you can now respond to client requests on tausi. We are happy to work with you!",
            verificationTitle: "Congratulations! Your application has been approved."
        }

        const updateVerificationQuery = await updateVerificationStatus(applicant.id, dataToSend);

        if(updateVerificationQuery.status === 200) {
            toast.success("Application Approved", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined
            });
        }else if (updateVerificationQuery.status === 400) {
            toast.error("Bad Request", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined
            });
        } else if (updateVerificationQuery.status === 401) {
            toast.error("Unauthorized", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined
            });
        } else {
            toast.error( updateVerificationQuery.message , {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined
            });
        }

    }

    // Handle Pop-Up
    const handleDeclineButton = () => {
        setIsOpen(true)
    }

    const handleCloseButton = () => {
        setIsOpen(false)
    }

    return (
        <>
        <ToastContainer />
        <div className="grid col-span-full bg-white rounded-xl shadow-xl py-3 px-5">
            <div className="flex flex-wrap">
                <div className="w-full">

                    <div className="grid gap-4 sm:flex items-center justify-between">
                        <ul
                            className="flex mb-0 list-none flex-wrap flex-row order-2 sm:order-1"
                            role="tablist"
                        >
                            <li className="-mb-px last:mr-0 flex-auto text-center">
                            <a
                                className={
                                "text-sm font-medium px-4 py-2 block leading-normal " +
                                (openApplicantTab === 1
                                    ? "text-black border-b-3 border-rounded-full border-red-500"
                                    : "text-gray-800 hover:text-gray-500")
                                }
                                onClick={e => {
                                e.preventDefault();
                                setOpenApplicantTab(1);
                                }}
                                data-toggle="tab"
                                href="#service"
                                role="tablist"
                            >
                                Service List
                            </a>
                            </li>

                            <li className="-mb-px last:mr-0 flex-auto text-center">
                            <a
                                className={
                                "text-sm font-medium px-4 py-2 block leading-normal " +
                                (openApplicantTab === 2
                                    ? "text-black border-b-3 border-rounded-full border-red-500"
                                    : "text-gray-800 hover:text-gray-500")
                                }
                                onClick={e => {
                                e.preventDefault();
                                setOpenApplicantTab(2);
                                }}
                                data-toggle="tab"
                                href="#product"
                                role="tablist"
                            >
                                Brands
                            </a>
                            </li>
                            <li className="-mb-px last:mr-0 flex-auto text-center">
                            <a
                                className={
                                "text-sm font-medium px-4 py-2 block leading-normal " +
                                (openApplicantTab === 3
                                    ? "text-black border-b-3 border-rounded-full border-red-500"
                                    : "text-gray-800 hover:text-gray-500")
                                }
                                onClick={e => {
                                e.preventDefault();
                                setOpenApplicantTab(3);
                                }}
                                data-toggle="tab"
                                href="#resume"
                                role="tablist"
                            >
                                Resume
                            </a>
                            </li>
                        </ul>
                    </div>

                    {/* Content */}
                    <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 rounded mt-5">
                        <div className="flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openApplicantTab === 1 ? "block" : "hidden"} id="service">
                                    <ServiceList services={services} />
                                </div>
                                <div className={openApplicantTab === 2 ? "block" : "hidden"} id="product">
                                    <ProductList services={services} />
                                </div>
                                <div className={openApplicantTab === 3 ? "block" : "hidden"} id="resume">
                                    <Resume resume={resume}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='grid justify-end mt-4'>
            <div className='flex space-x-4'>
                <div>
                    <button type="button" className="text-white bg-gray-400 hover:bg-gray-500 focus:ring-1 focus:ring-gray-300 font-medium rounded-lg text-sm px-10 py-2 focus:outline-none" onClick={e => handleDeclineButton(e)}>Decline</button>
                    <p className='pt-3 font-medium underline text-xs text-gray-900'>Request for more info</p>
                </div>
                <div>
                    <button type="button" className="text-white bg-blue-400 hover:bg-blue-500 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-sm px-10 py-2 focus:outline-none" onClick={e => handleApproveApplication(e)}>Approve</button>
                    <p className='pt-3 font-medium underline text-xs text-gray-900 hidden'>Request for more info</p>
                </div>
            </div>
        </div>
        <DeclineApplicant applicant={applicant} visible={isOpen} handleCloseButton={handleCloseButton}/>
        </>
    );
}

export default ApplicantTab