import React from 'react';

function PaymentCard({ data, balance }) {

    const diff = data.totalPrice - data.amountUpfront

    return (
        <div className="col-span-full bg-white shadow-lg rounded-xl border border-slate-200 p-6">
            <h2 className='font-bold text-lg text-gray-900'>Payment</h2>

            <div className='grid grid-cols-3 gap-8 items-center justify-start pt-4 pb-4'>
                <div className='grid'>
                    <p className='text-sm text-gray-800 text-opacity-70 font-semibold'>Total Amount:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>{data.totalPrice ? data.totalPrice : 0}</p>
                </div>
                <div className='grid'>
                    <p className='text-sm text-gray-800 text-opacity-70 font-semibold'>Booking Amount:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>{data.amountUpfront ? data.amountUpfront : 0}</p>
                </div>
                <div className='grid'>
                    <p className='text-sm text-gray-800 text-opacity-70 font-semibold'>Balance to be Paid:</p>
                    <p className='text-sm text-blue-700 font-semibold mt-1'>{diff}</p>
                </div>
                {/* <div className='grid items-end'>
                    <button type="button" className="text-red-500 bg-white border border-red-500 focus:outline-none hover:bg-gray-100 focus:ring-1 focus:ring-gray-200 font-medium rounded-full text-xs px-3 py-1">REFUNDED</button>
                </div> */}
            </div>
        </div>
  );
}

export default PaymentCard;
