import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../../src/axiosConfig'

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import SnapshotCard from '../partials/revenues/SnapshotCard';
// import ExportCard from '../partials/revenues/ExportCard';
// import DashboardCard08 from '../partials/dashboard/DashboardCard08';
// import DashboardCard04 from '../partials/dashboard/DashboardCard04';
// import DashboardCard06 from '../partials/dashboard/DashboardCard06';


function Revenues({ loggedUser, logout }) {

  const [data, setData] = useState(useMemo(() => [], []));

  const fetchData = async () => {
    await axiosInstance.get('/dashboard/orders/stats')
    .then(response => {
      setData(response.data.data);
    })
    .catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    fetchData()
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} title='Revenues' loggedUser={loggedUser} logout={logout}/>

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-gray-100">

            {/* Cards */}
            <div className="grid col-span-full sm:grid-cols-12 mb-10 space-y-6">
              
              <div className='grid grid-cols-2 md:grid-cols-4 gap-4 justify-between col-span-full w-full'>
                {/* Snapshot Cards */}
                <SnapshotCard time='Today' amount={data.todayRevenue} orders='0'/>
                <SnapshotCard time='Past week' amount={data.lastWeekRevenue} orders='8'/>
                <SnapshotCard time='Past month' amount={data.lastMonthRevenue} orders='53'/>
                <SnapshotCard time='Past year' amount={data.lastYearRevenue} orders='53'/>
                {/* Export Card */}
                {/* <ExportCard csvData={csvData} fileName='revenues'/> */}
              </div>
            </div>

              {/* <div className='grid grid-cols-12 gap-6'>
                <div className='col-span-full sm:col-span-6 space-y-6'>
                  <DashboardCard08 />
                  <DashboardCard04 />
                </div>

                <div className='col-span-full sm:col-span-6 space-y-6'>
                    <DashboardCard08 />
                    <DashboardCard06 />
                </div>
              </div> */}
          </div>
        </main>

      </div>
    </div>
  );
}

export default Revenues;