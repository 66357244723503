import axiosInstance from "../axiosConfig";

const axios = axiosInstance;

export const fetchAllProfessionalVerifications = async () => {
    let verificationData = { status: 200, message: '', data: {}};

    try {
        await axios.get(`/professionals/dashboard/verification`)
            .then(response => {
                if (response.status === 200) {
                    verificationData = { status: 200, message: response.data.message, data: response.data.data };
                    // console.log(verificationData.data);
                }
            })
            .catch((error) => {
                const res = error.response;
                if (res) {
                    verificationData = { status: res, message: res.data.message, data: res.data};
                }

                if(error.request) {
                    verificationData = { status: error.request, message: 'NETWORK ERROR', data: {}};
                }

                verificationData = { status: 500, message: 'INTERNAL SERVER ERROR', data: {}};
            });
    } catch (error) {
        console.log(error);
    };

    return verificationData;

};

export const updateVerificationStatus = async (professionalId = '', data = {}) => {
    let verificationData = { status: 200, message: '', data: {}};

    const payload = {
        ...data,
        reviewedBy: 'Tausi Admin'
    }

    try {
        await axios.patch(`/professionals/dashboard/verification/${professionalId}`, payload)
            .then(response => {
                if (response.status === 200) {
                    verificationData = { status: 200, message: response.data.message, data: response.data.data };
                }
            })
            .catch((error) => {
                const res = error.response;
                if (res) {
                    verificationData = { status: res, message: res.data.message, data: res.data};
                }

                if(error.request) {
                    verificationData = { status: error.request, message: 'NETWORK ERROR', data: {}};
                }

                verificationData = { status: 500, message: 'INTERNAL SERVER ERROR', data: {}};
            });
    } catch (error) {
        console.log(error);
    };

    return verificationData;
};

export const fetchProfessionalByStatus = async (status = '') => {
    let verificationData = { status: 200, message: '', data: {}};

    try {
        await axios.get(`/professionals/dashboard/verification?status=${status}&skip=0&limit=50000`)
            .then(response => {
                if (response.status === 200) {
                    verificationData = { status: 200, message: response.data.message, data: response.data.data };
                }
            })
            .catch((error) => {
                const res = error.response;
                if (res) {
                    verificationData = { status: res,message: res.data.message, data: res.data};
                }

                if(error.request) {
                    verificationData = { status: error.request, message: 'NETWORK ERROR', data: {}};
                }

                verificationData = { status: 500, message: 'INTERNAL SERVER ERROR', data: {}};
            });
    } catch (error) {
        console.log(error);
    };

    return verificationData; 
}

export const fetchVerificationById = async (professionalId = '') => {
    let verificationData = { status: 200, message: '', data: {}};

    try {
        await axios.get(`/professionals/dashboard/verification/${professionalId}`)
            .then(response => {
                if (response.status === 200) {
                    verificationData = { status: 200, message: response.data.message, data: response.data.data };
                }
            })
            .catch((error) => {
                const res = error.response;
                if (res) {
                    verificationData = { status: res,message: res.data.message, data: res.data};
                }

                if(error.request) {
                    verificationData = { status: error.request, message: 'NETWORK ERROR', data: {}};
                }

                verificationData = { status: 500, message: 'INTERNAL SERVER ERROR', data: {}};
            });
    } catch (error) {
        console.log(error);
    };

    return verificationData;
}
