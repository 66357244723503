import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../../axiosConfig';

import Table from '../../../tables/Table';
import UserModal from '../../modals/UserModal';


function FetchInactiveUsers() {

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Email",
            accessor: 'email',
        },
        {
            Header: "Phone",
            accessor: 'phoneNumber',
        },
        {
            Header: "Active Since",
            accessor: 'createdAt',
            id: 'Active Since',
            Cell: ({ row }) => {
                let date = row.original.createdAt
                return new Date(date).toDateString()
            }
        },

        {
            Header: "Status",
            accessor: d => d.isActive.toString(),
            Cell: ({ row }) => {
                const x = row.original
                return (
                    x.isActive === true ? 
                    <button className='text-xs py-1 px-4 bg-green-100 rounded-full text-green-500 cursor-default font-normal'>Active</button>                 
                    : 
                    <button className='text-xs py-1 px-4 bg-red-100 rounded-full text-red-500 cursor-default font-normal'>Inactive</button>
                )
            },
        },
        {
            Header: "Action",
            id: "action",
            Cell: ({ row }) => (
             <div>
                <button onClick={e => handleButtonClick(e, row)} className='text-white hover:bg-blue-600 text-xs font-medium py-1 px-4 rounded-full bg-blue-400'>
                    View
                </button>
             </div>
            )
        },
       
        
    ], []);
    const [data, setData] = useState(useMemo(() => [], []));
    const [visible, setVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [length, setLength] = useState(0)


    const fetchData = async () => {
        await axiosInstance.get('/users/?limit=100000000')
        .then(response => {
            const users = response.data.data;
            const activeUsers = users.filter(user => user.isActive === false)
            setData(activeUsers);
            setLength(activeUsers.length)
        })
        .catch(error => {
            console.log(error)
        })
    };
     
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (e, row) => {
        setVisible(true)
        let values = row.original
        setModalData(values)   
    };
    const handleCloseButton = () => { 
        setVisible(false)
    }

    return (
        <>
            <Table columns={columns} data={data} csvData={data} fileName='users' resource='User' total={length}/>
            <UserModal 
            isOpen={visible} 
            handleCloseButton={handleCloseButton} 
            name={modalData.name} 
            id={modalData.id}
            email={modalData.email}
            phoneNumber={modalData.phoneNumber}
            active={modalData.isActive}
            bio={modalData.bio}
            avatar={modalData.profilePictureUrl}
            userType={modalData.sessionData?.userTypeSession}
            businessName={modalData.professional?.businessName}
            locationPro={modalData.professional?.locationAddress}
            />
        </>
    )
}

export default FetchInactiveUsers;