import React from 'react'

const ConfirmModal = ({isOpen, handleCloseConfirmButton, handleContinueDeleteButton}) => {

    // Modal Body
    const modalBody = () => 
    <> 
        <div className='flex space-x-2 items-center py-3 px-3 rounded-xl'>
            <svg className="w-8 h-8 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
            <p className='text-lg font-medium text-gray-900'>Are you sure you want to delete!</p>
        </div>
    </>

  // Modal Back Button
  const modalButtons = () =>  
  <div className="mt-5 sm:mt-6 sm:flex space-x-3">
    <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-blue-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={handleCloseConfirmButton}>Cancel</button>
    <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-red-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={handleContinueDeleteButton}>Delete</button>
  </div>


  return (
    <div className={`overflow-y-auto ${isOpen ? 'fixed z-50 inset-0' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            {modalBody()}
          </div>
          {modalButtons()}
        </div>
      </div> 
    </div>   
  )
}

export default ConfirmModal