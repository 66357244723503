import React, { useState } from 'react';

function OngoingOrder({img, name, location, minutes}) {

    const [defaultUrl, setDefaultUrl] = useState(img)

    const setDefaultImage = () => {
        setDefaultUrl('https://media.istockphoto.com/vectors/woman-avatar-isolated-on-white-background-vector-illustration-for-vector-id1167753373?k=20&m=1167753373&s=612x612&w=0&h=88d0JshtPmZuJO3ybxNU9P-v-IHadT8PnPDNKdAFxCU=')
    }

  return (
    <li className="ml-4 group py-2">
        <div className="absolute w-3 h-3 bg-blue-500 rounded-full mt-1.5 -left-1.5 border border-white"></div>

        <div className="flex space-x-4">
            {/* Item 1 */}
            <div className="rounded-full">
                <img className="w-16 h-16 object-center object-cover rounded-full" src={defaultUrl} alt="img" onError={() => setDefaultImage()}/>
            </div>
            {/* Item 2 */}
            <div>
                <div className="flex space-x-2 md:space-x-3 items-center">
                    <div>
                        <h3 className="text-sm md:text-base font-medium text-gray-900">{name}</h3>
                        {/* <p className="text-gray-600 font-normal text-xs md:text-sm"> {desc}</p> */}
                    </div>
                    {/* time */}
                    <time className="text-xs font-normal leading-none text-gray-400">{minutes}</time>
                </div>
            
                {/* Location */}
                <div className="text-xs md:text-sm font-medium pt-1 flex space-x-2">
                    <div className="flex space-x-1 items-center">
                        <svg className="w-4 h-4 text-blue-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                        </svg>
                        <p className="text-xs md:text-sm font-medium text-gray-900">{location}</p>
                    </div>     
                </div>
            </div>
        </div>
    </li>
  );
}

export default OngoingOrder;
