
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import tokenReducer from "./features/user/tokenSlice";
import categoryReducer from './features/categories/categoriesSlice';
import ordersReducer from './features/orders/orderSlice';
import secondsReducer from './features/seconds/secondsSlice';

import { setupListeners } from "@reduxjs/toolkit/query";
import { usersApi } from "./services/users";
import { ordersApi } from "./services/orders";
import { servicesApi } from "./services/services";

// Save state in localstorage
export const saveState = (state) => {
  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch (e) {
    return;
  }
}

//After refresh, check if there is any data in the localstorage 
const loadState = () => {
  const serialState = localStorage.getItem('state');
  if (serialState === null || undefined) {
    return undefined;
  }
  return JSON.parse(serialState);
}

const persistedState = loadState();

//These are the reducers which do not change
const staticReducers = {
  token: tokenReducer,
  categories: categoryReducer,
  orders: ordersReducer,
  seconds: secondsReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
}

const initialStore = {
  ...persistedState
}


const createReducers = () => {
  return combineReducers({
    ...staticReducers,
  })
}

export const store = configureStore({
  reducer: createReducers(),
  preloadedState: initialStore,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(usersApi.middleware).concat(ordersApi.middleware).concat(servicesApi.middleware),
})

setupListeners(store.dispatch)