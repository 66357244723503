import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../axiosConfig';

import Table4 from './Table4';
import CategoryModal from '../partials/modals/CategoryModal';
import ConfirmModal from '../partials/modals/confirmModal';
import { toast } from 'react-toastify'

// Correct here
function FetchUsers() {

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Description",
            accessor: 'description',
        },    
        {
            Header: "Action",
            id: "action",
            Cell: ({ row }) => (
            <div>
                <button onClick={e => handleButtonClick(e, row)} className='text-white hover:bg-blue-600 text-xs font-medium py-1 px-4 rounded-full bg-blue-400'>
                    Edit
                </button>
                <button onClick={(e) => handleDeleteButton(e, row)} className='text-white hover:bg-red-600 text-xs font-medium ml-2 py-1 px-4 rounded-full bg-red-400'>
                    Delete
                </button>
            </div>
            )
        },
       
        
    ], []);
    const [data, setData] = useState(useMemo(() => [], []));
    const [visible, setVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [length, setLength] = useState(0)
    const [selectedImage, setSelectedImage] = useState(null);
    

    const fetchData = async () => {
        setLoading(true)
        await axiosInstance.get('/services/categories?limit=100000000')
        .then(response => {
            setData(response.data.data);
            setLoading(false)
            setLength(response.data.data.length)
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
    };
     
    useEffect(() => {
        fetchData();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (e, row) => {
        setVisible(true)
        let values = row.original
        setModalData(values)
        const { imageUrl } = values
        setSelectedImage(imageUrl)
        // console.log(values)
    };

    const handleCloseButton = () => {
        setVisible(false);
        fetchData();
    }

    const handleDeleteButton = (e, row) => {
        setIsOpen(true)
        setDeleteId(row.original.id)
    }
    const handleCloseConfirmButton = () => {
        setIsOpen(false)
    }
    const handleContinueDeleteButton = () => {
        axiosInstance.delete(`/services/categories/${deleteId}`)
        .then(() => {
            fetchData();
            toast.error('Category has been deleted!', { 
                autoClose: 4000, 
            })
            setIsOpen(false)
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <div className="grid col-span-full">
            <Table4 
            columns={columns} 
            data={data} 
            resource="Category" 
            func={() => fetchData()}
            isLoading={loading}
            total={length}
            />
            <CategoryModal 
            visible={visible} 
            handleCloseButton={handleCloseButton}
            modalData={modalData}
            selectedImage={selectedImage}
            func={() => fetchData()}
            />
            <ConfirmModal 
            isOpen={isOpen}
            handleCloseConfirmButton={handleCloseConfirmButton}
            handleContinueDeleteButton={handleContinueDeleteButton}
            />
        </div>
    )
}

export default FetchUsers;