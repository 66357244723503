import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../axiosConfig';
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux';

import Table2 from './Table2';
import ServiceModal from '../partials/modals/ServiceModal'
import ConfirmModal from '../partials/modals/confirmModal';

import { useGetServicesQuery, useDeleteServiceMutation } from '../services/services'

function FetchServices() {
    const categories = useSelector((state) => state.categories.value)

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Min-Price (Ksh)",
            accessor: 'minimumPrice',
        },
        {
            Header: "Description",
            accessor: 'description',
        },
        {
            Header: "Action",
            id: "Action",
            Cell: ({ row }) => (
            <div>
                <button onClick={e => handleButtonClick(e, row)} className='text-white hover:bg-blue-600 text-xs font-medium py-1 px-4 rounded-full bg-blue-400'>
                    Edit
                </button>
                <button onClick={(e) => handleDeleteButton(e, row)} className='text-white hover:bg-red-600 text-xs font-medium ml-2 py-1 px-4 rounded-full bg-red-400'>
                    Delete
                </button>
            </div>
            )
        },    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    const { data, refetch } = useGetServicesQuery({ count: 0 });

    // const fetchOrdersData = () => {
    //     setServiceData(services.data)
    //     setLength(services.data.length);
    //     setLoading(isLoading);
    // }
     
    // useEffect(() => {
    //     fetchOrdersData();
    //     refetch();
    // }, [services]);  

    const [servicesData, setServiceData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modalData, setModalData] = useState({})
    const [isOpen, setIsOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [loading, setLoading] = useState(true)
    const [selectedCat, setSelectedCat] = useState('')
    const [length, setLength] = useState(0)

    const fetchData = async () => {
        await axiosInstance.get('/services/?limit=10000000')
        .then(response => {
            const users = response.data.data;
            setServiceData(users);
            setLength(users.length)
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
    };

    useEffect(() => {
        const ac = new AbortController();
        fetchData();
        return () => ac.abort();
    }, []);


    const handleButtonClick = (e, row) => {
        setVisible(true)
        let values = row.original
        let ct = categories.find(cat => cat.id === values.category)
        setSelectedCat(ct)
        
        setModalData(values)
    };

    const handleDeleteButton = (e, row) => {
        setIsOpen(true)
        setDeleteId(row.original.id)
    }
    const handleCloseConfirmButton = () => {
        setIsOpen(false)
    }

    const [ deleteService, response ] = useDeleteServiceMutation();

    const handleContinueDeleteButton = () => {
        deleteService(deleteId);
        setIsOpen(false);
        refetch();
        toast.error('Service has been deleted!', { 
            autoClose: 4000, 
        })
        console.log(response);
    }

    const handleCloseButton = () => {
        setVisible(false)
    }

   
    return (
        <div className="grid col-span-full">
           
            <Table2 
            columns={columns} 
            data={servicesData} 
            func={() => refetch()} 
            resource="Service"
            isLoading={loading}
            total={length}
            />
          
            {/* Edit button */}
            <ServiceModal 
            isOpen={visible} 
            handleCloseButton={handleCloseButton} 
            modalData={modalData}
            selectedCategory={selectedCat}
            func={() => refetch()}
            />

            <ConfirmModal
            isOpen={isOpen}
            handleCloseConfirmButton={handleCloseConfirmButton}
            handleContinueDeleteButton={handleContinueDeleteButton}
            />
        </div>
    )
}

export default React.memo(FetchServices);

