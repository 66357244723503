import { createApi } from "@reduxjs/toolkit/query/react";
import axiosInstance from "../axiosConfig";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) => async ({ url, method, data, params }) => {
    try {
      const orders = await axiosInstance({ url: baseUrl + url, method, data, params })
      return { data: orders.data }
    } catch (axiosError) {
      let err = axiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
}

export const ordersApi = createApi({
    reducersPath: "orders",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://api.tausiapp.com/api/v2",
    }),
    tagTypes: ['Orders'],
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: () => `appointments?limit=1000000000`,
            method: 'get',
            providesTags: ['Orders'],
        })
    })
})

export const { useGetOrdersQuery } = ordersApi;
