import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axiosInstance from '../axiosConfig';
import { getCategories } from '../features/categories/categoriesSlice';
import { ToastContainer } from 'react-toastify'

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

// import DashboardCard04 from '../partials/dashboard/DashboardCard04';
// import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import TausiDashCard01 from '../partials/dashboard/TausiDashCard01';
// import TausiDashCard02 from '../partials/dashboard/TausiDashCard02';
import TausiDashCard03 from '../partials/dashboard/TausiDashCard03';

function Dashboard({loggedUser, logout}) {

  const dispatch = useDispatch();

  const fetchCategories = async () => {
      await axiosInstance.get('/services/categories')
      .then(response => {
          dispatch(getCategories(response.data.data))
      })
      .catch(error => {
          console.log(error)
      })
    };

  useEffect(() => {
    fetchCategories()
  })

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      <ToastContainer />

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} title='Dashboard' loggedUser={loggedUser} logout={logout}/>

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-gray-100">

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6 mb-10">

              {/* Grid 1 */}
              <div className="col-span-full sm:col-span-6 space-y-6">
                {/* Overstats  Card */}
                <TausiDashCard01 />
                {/* Line graph -orders */}
                {/* <DashboardCard08 /> */}
                {/* Top Beautician  Card */}
                {/* <TausiDashCard02 /> */}     
              </div>

              {/* Grid 2 */}
              <div className="col-span-full sm:col-span-6 space-y-6">
                {/* Ongoing Orders Card */}
                <TausiDashCard03 />
                {/* Bar graph - Revenue */}
                {/* <DashboardCard04 /> */}
              </div>           
            </div>
          </div>
        </main>

      </div>
    </div>
  );
}

export default React.memo(Dashboard);