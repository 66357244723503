import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { updateVerificationStatus } from '../../api/professional/verification';

const DeclineApplicant = ({ visible, handleCloseButton, applicant }) => {
  const [reason, setReason] = useState('Your application has been rejected because of missing information, you can come back in 2 weeks to check whether you have the opportunity to re-apply');

  const handleRejectApplication = async (e) => {
    e.preventDefault();
    const dataToSend = {
      verificationStatus: "rejected",
      verificationDescription: reason,
      verificationTitle: "We're sorry your application has been rejected"
    }

    const updateVerificationQuery = await updateVerificationStatus(applicant.id, dataToSend);

    if (updateVerificationQuery.status === 200) {
      toast.success("Application Declined", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined
      });
    } else if (updateVerificationQuery.status === 400) {
      toast.error("Bad Request", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined
      });
    } else if (updateVerificationQuery.status === 401) {
      toast.error("Unauthorized", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined
      });
    } else {
      toast.error(updateVerificationQuery.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined
      });
    }

    handleCloseButton();

  }
  return (
    <>
      <ToastContainer />
      <div className={`overflow-y-auto ${visible ? 'fixed z-50 inset-0' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div className="mt-4">
                <label htmlFor="description" className="block mb-2 text-base font-medium text-gray-900">Enter Reason for Declining</label>
                <textarea
                  id="reason"
                  name="reason"
                  rows="4"
                  onChange={e => setReason(e.target.value)}
                  defaultValue=""
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={reason}

                ></textarea>
              </div>
            </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" onClick={() => handleCloseButton()}>
                Cancel
              </button>

              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm cursor-pointer" onClick={(e) => handleRejectApplication(e)}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div></>
  )
}

export default DeclineApplicant