import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export const ExportCSV = ({csvData, fileName}) => { //Take in the data and the filename as props

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws  }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});  
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

  return (
    <button className='px-7 py-2 rounded-full bg-gray-400 text-white font-medium text-xs hover:bg-blue-500' onClick={(e) => exportToCSV(csvData,fileName)}>Export</button>
  )
}

