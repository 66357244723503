import axios from 'axios';
import { store } from '../store';

const axiosInstance = axios.create({
    baseURL: 'https://api.tausiapp.com/api/v2',
    headers: {
        accept: 'application/json',
        Authorization: `Bearer ${store.getState().token.token}`,
    }
});

axiosInstance.interceptors.request.use((config) => {
        const token = sessionStorage.getItem('Auth Token');
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
        return config;
    }
)

export default axiosInstance;
